import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  FormText,
  Input,
  Alert,
  Card,
  CardBody
} from "reactstrap";

import * as Yup from "yup";
import { Formik, Form } from "formik";
import { API_URL } from "../../helpers/Constants";

import HomeLoan from "./ProductForms/HomeLoan";
import LeaseRentalDiscounting from "./ProductForms/LeaseRentalDiscounting";
import LoanAgainstProperty from "./ProductForms/LoanAgainstProperty";
import WorkingCapital from "./ProductForms/WorkingCapital";
import BusinessLoan from "./ProductForms/BusinessLoan";
import PersonalLoan from "./ProductForms/PersonalLoan";
import BuilderAndProjectFunding from "./ProductForms/BuilderAndProjectFunding";

const applicationFormSchema = Yup.object().shape({
  fullName: Yup.string().required("Please enter your fullname"),
  email: Yup.string()
    .email()
    .required("Please enter your email"),
  mobileNumber: Yup.number().required("Please enter your mobile number"),
  loanType: Yup.string(),
  occupation: Yup.string(),//.required("Please select your Occupation"),
  monthlyIncome: Yup.number().min(20000,'Monthly Income Should be greater than Rs20000/-'),//.required("Please enter your monthly income"),
  city: Yup.string().required("Please enter your city"),
  pinCode: Yup.number().required("Please enter your pincode"),
  amount: Yup.number().required("Please enter your loan amount requirement"),
  message: Yup.string(),
  yearlyTurnOver: Yup.number().min(20000, 'Yearly Turnover Should be grater than Rs20000/-'),//.required('Please enter your yearly income'),
  yearlyProfit: Yup.number().min(20000, 'Yearly Profit Should be grater than Rs20000/-'),//.required('Please enter your yearly profit'),
  propertyType: Yup.string(),//.required('Please Enter the property type'),
  propertyLocation: Yup.string(),//.required('Please Enter the property location'),
  propertyValue: Yup.number(),//.required('Please Enter the property location'),
  rentReceived: Yup.number(),//.required('Please Enter the rent received'),
});

export default class ApplicationForm extends Component {
  state = {
    formSuccess: false,
    formError: false,
    isSubmitting: false,
    loanType: "PERSONAL LOAN",
    occupation: "SALARIED",
    applicationAllowed: true,
    salariedAvailable: true,
    selfEmployedAvailable: true,
  };

  handleApplicationSubmit = (form, actions) => {
    this.setState({ isSubmitting: true });
    form = {
      ...form,
      loanType: this.state.loanType,
      occupation: this.state.occupation,
    }
    fetch(`${API_URL}lead/create`, {
      method: "POST",
      body: JSON.stringify(form),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        this.setState({ formSuccess: true, isSubmitting: false });
        setTimeout(() => this.setState({ formSuccess: false }), 4000);
      })
      .catch(error => {
        this.setState({ formError: true, isSubmitting: false });
        setTimeout(() => this.setState({ formError: false }), 4000);
      });
    actions.isSubmitting = false;
    actions.resetForm();
  };

  handleLoanTypeChange = e => {
    this.setState({ loanType: e.target.value });
    
    switch (e.target.value) {
      case 'PERSONAL LOAN': 
        this.setState({salariedAvailable: true, selfEmployedAvailable: false, occupation: 'SALARIED'});
        break;
      case 'BUSINESS LOAN': 
        this.setState({salariedAvailable: false, selfEmployedAvailable: true, occupation: 'SELF_EMPLOYED'});
        break;
      case 'HOME LOAN': 
        this.setState({salariedAvailable: true, selfEmployedAvailable: true});
        break;
      case 'LOAN AGAINST PROPERTY': 
        this.setState({salariedAvailable: true, selfEmployedAvailable: true});
        break;
      case 'WORKING CAPITAL': 
        this.setState({salariedAvailable: false, selfEmployedAvailable: true, occupation: 'SELF_EMPLOYED'});
        break;
      case 'LEASE RENTAL DISCOUNTING': 
        this.setState({salariedAvailable: true, selfEmployedAvailable: true});
        break;
      case 'BUILDER AND PROJECT FUNDING': 
        this.setState({salariedAvailable: false, selfEmployedAvailable: true, occupation: 'SELF_EMPLOYED'});
        break;
      default:
        break;
    }

  };
  handleOccupationChange = e => {
    this.setState({ occupation: e.target.value });
  };
  render() {
    const loanType = this.state.loanType;
    return (
      <Container>
        <Row>
          <Col>
            <Card className="wow slideInLeft">
              <CardBody>
                <Row>
                  <Col sm={6}>
                    <FormGroup>
                      <Input
                        type="select"
                        onChange={this.handleLoanTypeChange}
                        value={this.state.loanType}
                        name="loanType"
                        placeholder="Loan Type"
                      >
                        <option value="PERSONAL LOAN">Personal loan</option>
                        <option value="BUSINESS LOAN">Business Loan</option>
                        <option value="HOME LOAN">Home Loan</option>
                        <option value="LOAN AGAINST PROPERTY">
                          Loan Against Property
                        </option>
                        <option value="LEASE RENTAL DISCOUNTING">
                          Lease Rental Discounting
                        </option>
                        <option value="WORKING CAPITAL">Working Capital</option>
                        <option value="BUILDER AND PROJECT FUNDING">
                          Builder and Project Funding
                        </option>
                      </Input>
                    </FormGroup>
                  </Col>
                  <Col sm={6}>
                    <FormGroup>
                      <Input
                        type="select"
                        onChange={this.handleOccupationChange}
                        value={this.state.occupation}
                        name="occupation"
                        placeholder="Occupation"
                      >
                        <option value="SELF_EMPLOYED" disabled={!this.state.selfEmployedAvailable}>Self Employed</option>
                        <option value="SALARIED" disabled={!this.state.salariedAvailable} >Salaried</option>
                      </Input>
                    </FormGroup>
                  </Col>
                </Row>
                <Formik
                  initialValues={{
                    fullName: "",
                    email: "",
                    mobileNumber: "",
                    monthlyIncome: undefined,
                    city: "",
                    pinCode: "",
                    amount: "",
                    message: "",
                    yearlyTurnOver: undefined,
                    yearlyProfit: undefined,
                    propertyValue: undefined,
                    propertyLocation: undefined,
                    propertyType: undefined,
                    projectConstructionType: undefined
                  }}
                  validationSchema={applicationFormSchema}
                  onSubmit={this.handleApplicationSubmit}
                  handleEmploymentChange={this.handleEmploymentChange}
                  render={props => {
                    return (
                      <Form onSubmit={props.handleSubmit}>
                        <Row>
                          <Col sm={6}>
                            <FormGroup>
                              <Input
                                type="text"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.fullName}
                                name="fullName"
                                placeholder="Full Name"
                              />
                              <FormText color="danger">
                                {props.errors.fullName &&
                                  props.touched.fullName && (
                                    <div>{props.errors.fullName}</div>
                                  )}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <Input
                                type="email"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.email}
                                name="email"
                                placeholder="Email"
                              />
                              <FormText color="danger">
                                {props.errors.email && props.touched.email && (
                                  <div>{props.errors.email}</div>
                                )}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <Input
                                type="number"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.mobileNumber}
                                name="mobileNumber"
                                placeholder="Mobile Number"
                              />
                              <FormText color="danger">
                                {props.errors.mobileNumber &&
                                  props.touched.mobileNumber && (
                                    <div>{props.errors.mobileNumber}</div>
                                  )}
                              </FormText>
                            </FormGroup>
                          </Col>

                          <Col sm={6}>
                            <FormGroup>
                              <Input
                                type="text"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.city}
                                name="city"
                                placeholder="City"
                              />
                              <FormText color="danger">
                                {props.errors.city && props.touched.city && (
                                  <div>{props.errors.city}</div>
                                )}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <Input
                                type="number"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.pinCode}
                                name="pinCode"
                                placeholder="Pincode"
                              />
                              <FormText color="danger">
                                {props.errors.pinCode &&
                                  props.touched.pinCode && (
                                    <div>{props.errors.pinCode}</div>
                                  )}
                              </FormText>
                            </FormGroup>
                          </Col>
                          <Col sm={6}>
                            <FormGroup>
                              <Input
                                type="number"
                                onChange={props.handleChange}
                                onBlur={props.handleBlur}
                                value={props.values.amount}
                                name="amount"
                                placeholder="Amount"
                              />
                              <FormText color="danger">
                                {props.errors.amount &&
                                  props.touched.amount && (
                                    <div>{props.errors.amount}</div>
                                  )}
                              </FormText>
                            </FormGroup>
                          </Col>

                          {loanType === "HOME LOAN" && (
                            <HomeLoan
                              {...props}
                              occupation={this.state.occupation}
                            />
                          )}
                          {loanType === "LEASE RENTAL DISCOUNTING" && (
                            <LeaseRentalDiscounting {...props} />
                          )}
                          {loanType === "LOAN AGAINST PROPERTY" && (
                            <LoanAgainstProperty
                              {...props}
                              occupation={this.state.occupation}
                            />
                          )}
                          {loanType === "WORKING CAPITAL" && (
                            <WorkingCapital
                              {...props}
                              occupation={this.state.occupation}
                            />
                          )}
                          {loanType === "BUSINESS LOAN" && (
                            <BusinessLoan
                              {...props}
                              occupation={this.state.occupation}
                            />
                          )}
                          {loanType === "PERSONAL LOAN" && (
                            <PersonalLoan
                              {...props}
                              occupation={this.state.occupation}
                            />
                          )}
                          {loanType === "BUILDER AND PROJECT FUNDING" && (
                            <BuilderAndProjectFunding
                              {...props}
                              occupation={this.state.occupation}
                            />
                          )}
                        </Row>
                        <FormGroup>
                          <Input
                            type="textarea"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.message}
                            name="message"
                            placeholder="Message"
                            rows="5"
                          />
                          <FormText color="danger">
                            {props.errors.message && props.touched.message && (
                              <div>{props.errors.message}</div>
                            )}
                          </FormText>
                        </FormGroup>
                        <Row>
                          <Col className="padding-20 d-flex justify-content-center col-md-12">
                            <Button
                              color="primary"
                              block
                              type="submit"
                              disabled={this.state.isSubmitting}
                            >
                              Submit
                            </Button>
                          </Col>
                        </Row>
                      </Form>
                    );
                  }}
                />
              </CardBody>
            </Card>
            {this.state.formSuccess && (
              <Alert color="success mt-5">
                Your Application submitted successfully, Our team will get back
                to you.
              </Alert>
            )}
            {this.state.formError && (
              <Alert color="danger mt-5">
                Error submitting Application try again
              </Alert>
            )}
          </Col>
        </Row>
      </Container>
    );
  }
}
