import React, { Component } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  FormGroup,
  Label,
  Input,
  FormText,
  Alert
} from "reactstrap";
import * as Yup from "yup";
import { Formik, Form } from "formik";
import { API_URL } from "../../helpers/Constants";

const contactUsSchema = Yup.object().shape({
  name: Yup.string().required("Please enter your Name"),
  email: Yup.string().required("Please enter your Email"),
  mobileNumber: Yup.number().required("Please enter your Mobile Number"),
  message: Yup.string().required("Please enter your message")
});
export default class ContactUsForm extends Component {
  handleContactUsSubmit = (formData, actions) => {
    console.log(formData);
    this.setState({ setSubmitting: true });
    fetch(`${API_URL}contactus/create`, {
      method: "POST",
      body: JSON.stringify(formData),
      headers: {
        "Content-Type": "application/json"
      }
    })
      .then(res => res.json())
      .then(res => {
        this.setState({ formSuccess: true, isSubmitting: false });
        setTimeout(() => this.setState({ formSuccess: false }), 4000);
      })
      .catch(error => {
        console.log(error);
        this.setState({ formError: true, isSubmitting: false });
        setTimeout(() => this.setState({ formError: false }), 4000);
        actions.setSubmitting = false;
      });
  };

  state = {
    formSuccess: false,
    formError: false,
    isSubmitting: false
  };
  render() {
    return (
      <div>
        <Container>
          <Row>
            <Col xs={12}>
              <h2>Drop a Message</h2>
            </Col>
            <Formik
              initialValues={{
                name: "",
                email: "",
                mobileNumber: "",
                message: ""
              }}
              validationSchema={contactUsSchema}
              onSubmit={(values, actions) =>
                this.handleContactUsSubmit(values, actions)
              }
              render={props => (
                <Form className="padding-top-50" onSubmit={props.handleSubmit}>
                  <FormGroup>
                    <Label for="name">Name</Label>
                    <Input
                      type="text"
                      name="name"
                      id="name"
                      placeholder="Name"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                    />
                    <FormText color="danger">
                      {props.errors.name && props.touched.name && (
                        <div>{props.errors.name}</div>
                      )}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="email">Email</Label>
                    <Input
                      type="email"
                      name="email"
                      id="email"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="Email"
                    />
                    <FormText color="danger">
                      {props.errors.email && props.touched.email && (
                        <div>{props.errors.email}</div>
                      )}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="mobileNumber">Mobile Number</Label>
                    <Input
                      type="number"
                      name="mobileNumber"
                      id="mobileNumber"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="Mobile Number"
                    />
                    <FormText color="danger">
                      {props.errors.mobileNumber &&
                        props.touched.mobileNumber && (
                          <div>{props.errors.mobileNumber}</div>
                        )}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Label for="message">Message</Label>
                    <Input
                      type="textarea"
                      name="message"
                      id="message"
                      onChange={props.handleChange}
                      onBlur={props.handleBlur}
                      placeholder="Message"
                      rows={4}
                    />
                    <FormText color="danger">
                      {props.errors.message && props.touched.message && (
                        <div>{props.errors.message}</div>
                      )}
                    </FormText>
                  </FormGroup>
                  <FormGroup>
                    <Button
                      color="primary"
                      type="submit"
                      disabled={this.state.isSubmitting}
                    >
                      Submit
                    </Button>
                  </FormGroup>
                </Form>
              )}
            />
            {this.state.formSuccess && (
              <Alert color="success mt-5">
                Your Application submitted successfully, Our team will get back
                to you.
              </Alert>
            )}
            {this.state.formError && (
              <Alert color="danger mt-5">
                Error submitting Application try again
              </Alert>
            )}
            <Col xs={12} sm={6} />
          </Row>
        </Container>
      </div>
    );
  }
}
