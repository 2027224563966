import React from 'react'
import PageHeader from '../components/PageHeader/PageHeader';
import Faqs from '../components/faq/Faqs';
import Data from '../data.js';
import ApplyNow from '../components/applyNow/ApplyNow';

 const FaqList = () => {
   const categories = Object.keys(Data.faqs);
   let faqs =[]; 
   categories.map(value => {
     let items = Data.faqs[value].questions;
     return faqs = [...faqs, ...items];
   });
  return (
    <div>
      <PageHeader title="FAQs" sectionClass="personal-loan" />
      <div className="mt-5">
      <Faqs  items= {faqs} search={true}/>
      </div>
      <ApplyNow/>
    </div>
  )
}
export default FaqList;