import React, { Component, Fragment } from "react";
import { Col, FormGroup, FormText, Input } from "reactstrap";

export default class WorkingCapital extends Component {
  state = {
    type: this.props.occupation || "SELF_EMPLOYED"
  };
  render() {
    const occupation = this.props.occupation || "SELF_EMPLOYED";
    console.log('in', occupation)
    return (
      <Fragment>
        
        {occupation === "SELF_EMPLOYED" ? (
          <Fragment>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.yearlyTurnOver}
                  name="yearlyTurnOver"
                  placeholder="Yearly Turnover"
                  required

                />
                <FormText color="danger">
                  {this.props.errors.yearlyTurnOver &&
                    this.props.touched.yearlyTurnOver && (
                      <div>{this.props.errors.yearlyTurnOver}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.yearlyProfit}
                  name="yearlyProfit"
                  placeholder="Yearly Income"
                  required
                />
                <FormText color="danger">
                  {this.props.errors.yearlyProfit &&
                    this.props.touched.yearlyProfit && (
                      <div>{this.props.errors.yearlyProfit}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
          </Fragment>
        ) : null}
       
      </Fragment>
    );
  }
}
