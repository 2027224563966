import React, { Component } from "react";
import {
  Row,
  Col,
  FormGroup,
  Label,
  Input,
  Card,
  CardBody,
  ButtonGroup,
  Button,
  InputGroup,
  InputGroupAddon
} from "reactstrap";
import { Form, Formik } from "formik";
import C3Chart from 'react-c3js';
import 'c3/c3.css';
import AmortizationSchedule from "./AmortizationSchedule";

export default class EMICalculator extends Component {
  state = {
    tenureType: 1,
    emi: 0,
    principal: 0,
    interest: 0,
    tenure: 0,
    roi: 0
  };
  // 1- Years 2- Months

  setTenure = tenureType => {
    this.setState({ tenureType: tenureType });
  };

  handleCalculateEMI = (formData, actions) => {
        let { amount, interestRate, tenure } = formData;
        let roi = 0;
        let emi = 0, emiValue = 0;
       
        amount = parseFloat(amount);
        tenure = parseFloat(tenure);
        interestRate = parseFloat(interestRate);
          roi = interestRate;
         if (this.state.tenureType === 1)
            tenure = tenure * 12;
        interestRate=interestRate/1200 ;

        let k = Math.pow((1 + interestRate),tenure)
        emiValue = (amount * k * interestRate) / (k - 1);
        emi = Math.round(emiValue);
        const interestAmount = (emi * tenure) - amount;
        debugger;
        this.setState({
            emi: emi.toLocaleString('en-IN'),
            principal: amount,
            interest: interestAmount,
            tenure: tenure,
            roi: roi
        })
  };
  render() {
    let size = { size: 8, offset: 2 }
    if(this.props.size === "full")
      size = {size:12};
    return (
      <div className="mb-5 wow slideInLeft">
        <Col md={size}>
          <Card>
            <CardBody>
              <Formik
                initialValues={{
                  amount: "",
                  interestRate: 10,
                  tenure: 15,
                }}
                onSubmit={(values, actions) => {
                    this.handleCalculateEMI(values, actions);
                  }}
                render={props => (
                  <Form  onSubmit={props.handleSubmit} className="text-left emi-calculator-inputs">
                    <FormGroup row>
                      <Col sm={3}>
                        <Label for="amount">Loan Amount</Label>
                      </Col>
                      <Col sm={9}>
                        <InputGroup>
                          <Input
                            type="number"
                            name="amount"
                            id="amount"
                            placeholder="Loan Amount"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.amount}
                            required
                          />
                          <InputGroupAddon addonType="append">
                            <Button disabled color="primary">
                              &#8377;
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={3}>
                        <Label for="name">Interest Rate</Label>
                      </Col>
                      <Col sm={9}>
                        <InputGroup>
                          <Input
                            type="number"
                            name="interestRate"
                            id="interestRate"
                            placeholder="Interest Rate"
                            onChange={props.handleChange}
                            onBlur={props.handleBlur}
                            value={props.values.interestRate}
                          />
                          <InputGroupAddon addonType="append">
                            <Button disabled color="primary">
                              %
                            </Button>
                          </InputGroupAddon>
                        </InputGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup row>
                      <Col sm={3}>
                        <Label for="name">Tenure</Label>
                      </Col>
                      <Col xs={7} sm={6}>
                        <Input
                          type="number"
                          name="tenure"
                          id="tenure"
                          placeholder="Tenure"
                          onChange={props.handleChange}
                          onBlur={props.handleBlur}
                          value={props.values.tenure}
                        />
                      </Col>
                      <Col xs={5} sm={3}>
                        <ButtonGroup>
                          <Button
                            onClick={() => this.setTenure(1)}
                            color="primary"
                            type="button"
                            className={this.state.tenureType === 1 ? "active" : null}
                          >
                            Yr
                          </Button>
                          <Button
                            onClick={() => this.setTenure(2)}
                            color="primary"
                            type="button"
                            className={this.state.tenureType === 2 ? "active" : null}

                          >
                            Mo
                          </Button>
                        </ButtonGroup>
                      </Col>
                    </FormGroup>
                    <FormGroup className="border-bottom-none text-center">
                      <Button color="primary" type="submit" block size="lg">
                        Calculate
                      </Button>
                    </FormGroup>
                  </Form>
                )}
              />
              <Row className="results">
                <Col sm={6}>
                  <Row>
                    <Col xs={12} className="emi-detail-values">
                      <p>Loan EMI</p>
                      <h3>&#8377;{this.state.emi}</h3>
                    </Col>
                    <Col xs={12} className="emi-detail-values">
                      <p>Total Interest Payable</p>
                      <h3>&#8377;{this.state.interest}</h3>
                    </Col>
                    <Col
                      xs={12}
                      className="emi-detail-values border-bottom-none"
                    >
                      <p>Total Payment (Principal + Interest)</p>
                      <h3>&#8377;{this.state.principal + this.state.interest}</h3>
                    </Col>
                  </Row>
                  </Col>

                  <Col sm={6}>
                  <C3Chart
                     data={{
                      columns: [
                          ['Principal', this.state.principal],
                          ['Interest', this.state.interest],
                      ],
                      type : 'pie',
                    }}
                  />
                </Col>
                {!!this.state.emi && 
                <Col xs={12}> 
                <AmortizationSchedule 
                  principal={this.state.principal}
                  tenure={this.state.tenure}
                  interestRate={this.state.roi}
                />
                </Col>
                }
                
              </Row>
            </CardBody>
          </Card>
        </Col>
        <Row />
      </div>
    );
  }
}
