import React, { Component, Fragment } from "react";
import { Col, FormGroup, FormText, Input } from "reactstrap";

export default class BuilderAndProjectFunding extends Component {
  state = {
    type: this.props.occupation || "SELF_EMPLOYED"
  };
  render() {
    const occupation = this.props.occupation || "SELF_EMPLOYED";
    return (
      <Fragment>
        {occupation === "SELF_EMPLOYED" ? (
          <Fragment>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="select"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.projectConstructionType}
                  name="projectConstructionType"
                  placeholder="Project Construction Type"
                >
                  <option value="SRA">SRA</option>
                  <option value="REDEVELOPMENT">Redevelopment</option>
                  <option value="OWN LAND">Own Land</option>
                </Input>
                <FormText color="danger">
                  {this.props.errors.projectConstructionType &&
                    this.props.touched.projectConstructionType && (
                      <div>{this.props.errors.projectConstructionType}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="text"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.projectCompletionStatus}
                  name="projectCompletionStatus"
                  placeholder="Project Completion Status"
                  required
                />
                <FormText color="danger">
                  {this.props.errors.projectCompletionStatus &&
                    this.props.touched.projectCompletionStatus && (
                      <div>{this.props.errors.projectCompletionStatus}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="text"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.projectLocation}
                  name="projectLocation"
                  placeholder="Project Location"
                  required
                />
                <FormText color="danger">
                  {this.props.errors.projectLocation &&
                    this.props.touched.projectLocation && (
                      <div>{this.props.errors.projectLocation}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
          </Fragment>
        ) : null}
      </Fragment>
    );
  }
}
