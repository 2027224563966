import React, { Component, Fragment } from "react";
import { Col, FormGroup, FormText, Input } from "reactstrap";

export default class PersonalLoan extends Component {
  state = {
    type: this.props.occupation || "SELF_EMPLOYED"
  };
  render() {
    const occupation = this.props.occupation || "SELF_EMPLOYED";
    return (
      <Fragment>
        {occupation === "SELF_EMPLOYED" ? null: (
          <Fragment>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.monthlyIncome}
                  name="monthlyIncome"
                  placeholder="Monthly Income"
                  required
                />
                <FormText color="danger">
                  {this.props.errors.monthlyIncome &&
                    this.props.touched.monthlyIncome && (
                      <div>{this.props.errors.monthlyIncome}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
          </Fragment>
        )}
       
      </Fragment>
    );
  }
}
