import React from "react";
import { Row, Container, Col, Card, CardBody } from "reactstrap";
import SubmitDocuments from "../../assets/images/documents.png";
import Verification from "../../assets/images/verification.png";
import Disbursal from "../../assets/images/disburse.png";

const HowItWorks = () => {
  return (
    <Container fluid className="how-it-works">
      <Row>
        <Col>
          <Container>
            <Row className="text-center">
              <Col xs={12} className="mt-5 mb-5">
                
                <h2>How It Works</h2>
              </Col>
              <Col xs={12} md={4}>
                <Card className="icon-body wow slideInLeft">
                  <CardBody>
                    <img
                      src={SubmitDocuments}
                      alt="submit-documents-angel-capital"
                      className="img-fluid icon"
                    />
                    <h3>Submit Documents</h3>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card className="wow slideInUp">
                  <CardBody>
                    <img
                      src={Verification}
                      alt="submit-documents-angel-capital"
                      className="img-fluid icon"
                    />
                    <h3>Document Verification</h3>
                  </CardBody>
                </Card>
              </Col>
              <Col xs={12} md={4}>
                <Card className="wow slideInRight">
                  <CardBody>
                    <img
                      src={Disbursal}
                      alt="submit-documents-angel-capital"
                      className="img-fluid icon"
                    />
                    <h3>Loan Disbursal</h3>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </Col>
      </Row>
    </Container>
  );
};
export default HowItWorks;
