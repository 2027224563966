import React from "react";

export default props => {
  return (
    <div className="service-header">
      <div className={props.sectionClass}>
        <h1>{props.title}</h1>
      </div>
    </div>
  );
};
