import React, { Component, Fragment } from "react";
import { Col, FormGroup, FormText, Input } from "reactstrap";

export default class HomeLoan extends Component {
  state = {
    type: this.props.occupation || "SELF_EMPLOYED"
  };
  render() {
    const occupation = this.props.occupation || "SELF_EMPLOYED";
    return (
      <Fragment>
           <Col sm={6}>
          <FormGroup>
            <Input
              type="number"
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              value={this.props.values.propertyValue}
              name="propertyValue"
              placeholder="Property Value"
              required
            />
            <FormText color="danger">
              {this.props.errors.propertyValue &&
                this.props.touched.propertyValue && (
                  <div>{this.props.errors.propertyValue}</div>
                )}
            </FormText>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Input
              type="text"
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              value={this.props.values.propertyLocation}
              name="propertyLocation"
              placeholder="Property Location"
              required

            />
            <FormText color="danger">
              {this.props.errors.propertyLocation &&
                this.props.touched.propertyLocation && (
                  <div>{this.props.errors.propertyLocation}</div>
                )}
            </FormText>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Input
              type="select"
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              value={this.props.values.propertyType}
              name="propertyType"
              placeholder="Property Type"
              required
            >
              <option>UNDER CONSTRUCTION</option>
              <option>CONSTRUCTED</option>
            </Input>
            <FormText color="danger">
              {this.props.errors.propertyType &&
                this.props.touched.propertyType && (
                  <div>{this.props.errors.propertyType}</div>
                )}
            </FormText>
          </FormGroup>
        </Col>
        {occupation === "SELF_EMPLOYED" ? (
          <Fragment>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.yearlyTurnOver}
                  name="yearlyTurnOver"
                  placeholder="Yearly Turnover"
                  required

                />
                <FormText color="danger">
                  {this.props.errors.yearlyTurnOver &&
                    this.props.touched.yearlyTurnOver && (
                      <div>{this.props.errors.yearlyTurnOver}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.yearlyProfit}
                  name="yearlyProfit"
                  placeholder="Yearly Income"
                  required
                />
                <FormText color="danger">
                  {this.props.errors.yearlyProfit &&
                    this.props.touched.yearlyProfit && (
                      <div>{this.props.errors.yearlyProfit}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
          </Fragment>
        ) : (
          <Fragment>
            <Col sm={6}>
              <FormGroup>
                <Input
                  type="number"
                  onChange={this.props.handleChange}
                  onBlur={this.props.handleBlur}
                  value={this.props.values.monthlyIncome}
                  name="monthlyIncome"
                  placeholder="Monthly Income"
                  required
                />
                <FormText color="danger">
                  {this.props.errors.monthlyIncome &&
                    this.props.touched.monthlyIncome && (
                      <div>{this.props.errors.monthlyIncome}</div>
                    )}
                </FormText>
              </FormGroup>
            </Col>
          </Fragment>
        )}
       
      </Fragment>
    );
  }
}
