import React, { Fragment } from "react";
import GenerateAmmortizationSchedule from "../../helpers/GenerateAmmortizationSchedule";
import AmortizationTable from "./AmortizationSchedule/AmortizationTable";


export default function AmortizationSchedule(props) {
  const input = {};
  let ammortizationTable = <div />;
  if (props.principal) {
    input.amortization = GenerateAmmortizationSchedule(
      props.principal,
      props.tenure,
      props.interestRate
    );
    const idVal = Math.round(Math.random() * 100000)
    const tableId = `${props.principal}-${props.tenure}-${idVal}`;
    ammortizationTable = (
      <Fragment>
        <AmortizationTable input={input} tableId={tableId} />
      </Fragment>
    );
  }
  return (
    <div>
      {ammortizationTable}
    </div>
  );
}
