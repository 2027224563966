import React from "react";
import { Container, Row, Col} from "reactstrap";

const HelpingGrow = () => {
  return (
    <div className="help-you-grow help-you-grow-main">
      <Container>
        <Row>
          
          <Col sm={12} className="why-angel-capital font-white wow slideInLeft">
            
                <h2 className="text-center font-white">Why Angel Capital?</h2>
                <br/>
                <Row noGutters className="text-center d-md-none">
                  <Col sm={4} className="border-bottom why-attribute">
                  <h5>
                    Compare Loan Products
                  </h5>
                  </Col>
                  <Col sm={4} className="border-bottom why-attribute">
                  <h5>
                    Customized Solutions
                  </h5>
                  </Col>
                  <Col sm={4} className="border-bottom why-attribute">
                  <h5>
                    Easy Application Process
                  </h5>
                  </Col>
                  <Col sm={4} className="border-bottom  why-attribute">
                  <h5>
                    Efficient Support System
                  </h5>
                  </Col>
                  <Col sm={4} className="border-bottom  why-attribute">
                  <h5>
                  EMI Calculator
                  </h5>
                  </Col>
                  <Col sm={4} className="border-bottom why-attribute">
                  <h5>
                  Assured Privacy
                  </h5>
                  </Col>
                </Row>
              <div className="d-none d-md-block">
                <Row noGutters  className="text-center ">
                  <Col sm={4} className="border-right why-attribute">
                  <h5>
                    Compare Loan Products
                  </h5>
                  </Col>
                  <Col sm={4} className="border-right why-attribute">
                  <h5>
                    Customized Solutions
                  </h5>
                  </Col>
                  <Col sm={4} className="why-attribute">
                  <h5>
                    Easy Application Process
                  </h5>
                  </Col>
                  <Col sm={4} className="border-right border-top why-attribute">
                  <h5>
                    Efficient Support System
                  </h5>
                  </Col>
                  <Col sm={4} className="border-right border-top why-attribute">
                  <h5>
                  EMI Calculator
                  </h5>
                  </Col>
                  <Col sm={4} className="border-top why-attribute">
                  <h5>
                  Assured Privacy
                  </h5>
                  </Col>
                </Row>
                </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default HelpingGrow;
