import React from 'react';
import moment from 'moment';
import currencyFormatter from 'currency-formatter';
import { at, sumBy, times } from 'lodash';
import { Fragment } from 'react';

const cf = currencyFormatter.format;

const cellStyle = {
  padding: '5px 0',
  margin: '0',
};

const summaryCellStyle = {
  padding: '5px 0',
  margin: '0',
  fontWeight: '700',
};

const summaryRowStyle = {
  backgroundColor: '#8bc8f9',
};

const displayDateForPayment = (props, format = 'MMM YYYY') => (
  moment(props.input.beginDate)
    .add(props.row.paymentNumber, 'months')
    .format(format)
);

const getMonthForPayment = (props) => (
  moment(props.input.beginDate)
    .add(props.row.paymentNumber, 'months')
    .month() + 1
);

const isLastPayment = (props) => {
  const { amortization } = props.input;
  return props.row.paymentNumber === amortization[amortization.length - 1].paymentNumber;
};

const shouldShowAnnualSummaryRow = (props) => (
  displayDateForPayment(props).includes('Dec') || isLastPayment(props)
);

const displayAnnualTotal = (props, attribute) => {
  const { row, input } = props;
  const indexRange = [];
  const monthNumber = getMonthForPayment(props);
  let rowIndex = row.paymentNumber - 1;

  times(monthNumber, () => {
    if (rowIndex >= 0) {
      indexRange.push(rowIndex);
      rowIndex--;
    }
  });
  const amortizationSubArray = at(input.amortization, indexRange);
  return cf(sumBy(amortizationSubArray, attribute), { code: 'INR' });
};

const displayDataCell = (content) => (
  <td xs={2} className="table-cell" style={cellStyle}>
    <div>{content}</div>
  </td>
);

const displaySummaryCell = (content) => (
  <td xs={2} className="table-summaryCell"  style={summaryCellStyle}>
    <div>{content}</div>
  </td>
);

const AmortizationTableRow = (props) => {
  // props.addTableRow({
  //   date: displayDateForPayment(props),
  //   paymentRounded: cf(props.row.paymentRounded, { code: 'INR' }),
  //   principalPaymentRounded: cf(props.row.principalPaymentRounded, { code: 'INR' }),
  //   interestPaymentRounded: cf(props.row.interestPaymentRounded, { code: 'INR' }),
  //   principalBalanceRounded: cf(props.row.principalBalanceRounded, { code: 'INR' }),
  // })
  // // shouldShowAnnualSummaryRow(props) && props.addTableRow({
  // //   paymentDate: displayDateForPayment(props, 'YYYY'),
  // //   annualTotalPrincipalRounded: ''
  // })
  return (
  <Fragment>
    <tr>
      {displayDataCell(displayDateForPayment(props))}
      {displayDataCell(cf(props.row.paymentRounded, { code: 'INR' }))}
      {displayDataCell(cf(props.row.principalPaymentRounded, { code: 'INR' }))}
      {displayDataCell(cf(props.row.interestPaymentRounded, { code: 'INR' }))}
      {/* {displayDataCell(cf(props.row.accInterestRounded, { code: 'INR' }))} */}
      {displayDataCell(cf(props.row.principalBalanceRounded, { code: 'INR' }))}
    </tr>
    {shouldShowAnnualSummaryRow(props) &&
      <tr style={summaryRowStyle}>
        {displaySummaryCell(displayDateForPayment(props, 'YYYY'))}
        {displaySummaryCell(displayAnnualTotal(props, 'paymentRounded'))}
        {displaySummaryCell(displayAnnualTotal(props, 'principalPaymentRounded'))}
        {displaySummaryCell(displayAnnualTotal(props, 'interestPaymentRounded'))}
        {/* {displaySummaryCell(cf(props.row.accInterestRounded, { code: 'INR' }))} */}
        {displaySummaryCell(cf(props.row.principalBalanceRounded, { code: 'INR' }))}
      </tr>
    }
  </Fragment>)
};

export default AmortizationTableRow;