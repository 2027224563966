import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "../components/PageHeader/PageHeader";
import ContactUsForm from "../components/forms/ContactUs";

export default class ContactUs extends Component {
  render() {
    return (
      <div className="contact-us">
        <PageHeader title="Contact us" sectionClass="personal-loan" />

        <Container className='padding-top-50'>
          <Row>
            <Col xs={12} md={6} className="wow slideInLeft">
              <ContactUsForm />
            </Col>
            <Col xs={12} md={6} className="wow slideInRight" >
              <Row >
                <Col xs={12}>
                  <h3>Our Address</h3>
                </Col>
                <Col xs={12} sm={3} className='padding-top-50'>
                  <i className="fas fa-map-marked-alt" />
                </Col>
                <Col sm={8} className='padding-top-50'>
                  <div className="text-contact">
                  <strong>Address :</strong><br/>
                    <a href='/#' className='text'>
                    510, Dimple Arcade Asha Nagar Behind Sai Dham, Thakur
                    Complex, Kandivali East, Mumbai 400101</a>
                  </div>
                </Col>
              </Row>
              <Row className='padding-top-50'>
                <Col xs={12} sm={3}>
                  <i className="fas fa-envelope" />
                </Col>
                <Col sm={8}>
                  <div className="text-contact">
                    <strong>Email :</strong><br/>
                    <a href="mailto:cs@angelcapital.in" className='text'>cs@angelcapital.in</a>
                  </div>
                </Col>
              </Row>
              <Row className='padding-top-50'> 
                <Col xs={12} sm={3}>
                  <i className="fas fa-phone-square" />
                </Col>
                <Col sm={8}>
                  <div className="text-contact">
                    <strong>Landline Number :</strong><br/>
                    <a href="tel:02249729699" className='text'>022 - 49729699</a>
                    <br/>
                          Monday - Friday (10 am to 6.30 pm)<br/>
                          Saturday (10 am to 2 pm)
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} className='padding-top-50'>
            
            </Col>
          </Row>
        </Container>
        <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15071.042671452797!2d72.8729471633743!3d19.205652954085906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7f729fd0319%3A0xc246833a07d3e983!2sAngel+Capital+-+Loan+Aggregators!5e0!3m2!1sen!2sin!4v1554632967220!5m2!1sen!2sin"
                width="100%"
                height="550"
                frameBorder="0"
                style={{ border: 0, marginBottom: '-110px'}}
                allowFullScreen
                title="Angel Capital"
              />
      </div>
    );
  }
}
