import React, { Component } from "react";
import { Container, Col, Row } from "reactstrap";
import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";

const settings = {
  dots: false,
  infinite: true,
  speed: 2000,
  slidesToShow: 1,
  slidesToScroll: 1,
  // fade: true,
  // lazyLoad: true,
  autoplay: true
};
class HeroCarousel extends Component {
  render() {
    return (
      <Container fluid className="hero-background">
        <Row className="carousel" style={{ width: "100%" }}>
          <Slider {...settings}>
          <div key={1}>
              <Col xs={12} md={{size:6, offset:6}} className="slideDiv">
                <h1>Welcome to Angel Capital</h1>
                <h4 className="mt-3">India's Leading <strong>Loan Aggregator</strong></h4>
                <a
                  href="/about-us"
                  className="btn btn-primary hero-buttons mt-3"
                >
                  Read More
                </a>
              </Col>
            </div>
            <div key={1}>
              <Col xs={12} md={{size:6, offset:6}} className="slideDiv">
                <h1>Personal Loans For you</h1>
                <h4 className="mt-3">Loans for when <strong>Life Happens</strong></h4>

                <a
                  href="/personal-loan"
                  className="btn btn-primary hero-buttons mt-5"
                >
                  Read More
                </a>
              </Col>
            </div>
            <div key={2}>
              <Col xs={12} md={{size:6, offset:6}} className="slideDiv">
                <h1>Business Loans For you</h1>
                <h4 className="mt-3">The first step to <strong>Financial Freedom</strong></h4>

                <a
                  href="/business-loan"
                  className="btn btn-primary hero-buttons mt-5"
                >
                  Read More
                </a>
              </Col>
            </div>
            <div key={3}>
              <Col xs={12} md={{size:6, offset:6}} className="slideDiv">
                <h1>Home Loans For you</h1>
                <h4 className="mt-3">Build a home for <strong>Independence</strong></h4>

                <a
                  href="/personal-loan"
                  className="btn btn-primary hero-buttons mt-5"
                >
                  Read More
                </a>
              </Col>
            </div>
          </Slider>
        </Row>
      </Container>
    );
  }
}

export default HeroCarousel;
