import React from 'react';
import {Container, Row , Col} from 'reactstrap';
import PageHeader from '../components/PageHeader/PageHeader';

export default function PrivacyPolicy() {
  return (
    <div>
      <PageHeader sectionClass='personal-loan' title='Page Not Found'/>
      <Container><Row><Col className="text-center mt-5"><h1>Looks like you have typed an incorrect Url</h1></Col></Row></Container>
      </div>)
      
    }