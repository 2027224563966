import React from 'react';
import { Card, CardImg, CardText, CardBody,
    CardTitle } from 'reactstrap';
  

const ServiceCard = (props) => {

  return (
    <div className="mb-5">
      <Card>
        <CardImg top width="100%" src={require(`../../assets/images/${props.cardImage}`)} alt="Card image cap" style={props.isExpanded ? {height:'250px'} : {height:'180px'}} className="card-cap-services"/>
        <CardBody>
          <CardTitle>{props.title}</CardTitle>
          {!!props.isExpanded ?
            <CardText>{props.cardText}</CardText> : null}
          <a href={props.href}>Read More...</a>
        </CardBody>
      </Card>
    </div>
  )
}
export default ServiceCard;