import React from "react";
import { Container, Row, Col } from "reactstrap";
import Logo from "../../assets/images/angel-capital-logo-1.png";
export default () => {
  return (
    <div>
      <Container fluid className="footer">
        <Container>
          <Row>
            <Col xs={12}>
              <Container>
                <Row className="marging-negative-80">
                  <Col xs={12} sm={4} className="footer-contact">
                    <Row>
                      <Col xs={3} sm={3}>
                        <i className="fas fa-map-marked-alt" />
                      </Col>
                      <Col xs={9} sm={8}>
                        <div className="text-contact">
                          510, Dimple Arcade Asha Nagar Behind Sai Dham, Thakur
                          Complex, Kandivali East, Mumbai 400101
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={4} className="footer-contact">
                    <Row>
                      <Col xs={3} sm={3}>
                        <i className="fas fa-envelope" />
                      </Col>
                      <Col xs={9} sm={8}>
                        <div className="text-contact">
                          Email :<br />
                          <a href="mailto:cs@angelcapital.in">
                            cs@angelcapital.in
                          </a>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12} sm={4} className="footer-contact">
                    <Row>
                      <Col xs={3} sm={3}>
                        <i className="fas fa-phone-square" />
                      </Col>
                      <Col xs={9} sm={8}>
                        <div className="text-contact">
                          Landline Number :<br />
                          <a href="tel:02249729699">022 - 49729699</a>
                          <br/>
                          Mon - Fri (10 am to 6.30 pm)
                          <br/>
                          Sat (10 am to 2 pm)

                        </div>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Container>
            </Col>
          </Row>

          <Row className="footer-content">
            <Col xs={12} sm={6} md={6}>
              <img
                src={Logo}
                className="img-fluid logo-footer"
                alt="angel-capital-logo"
              />
              <p className="text mt-5">
              We are the fastest-growing organization in the loan aggregation space in India. Tired
              of endless bank visits and fake phone commitments, we at Angel
              Capital provide you with financial advisory. Led by
              passionate problem-solvers and backed by advisors with a decade+ years of
              experience in finances and investment
             
              </p>
              <a href="/" className="mt-2 ">
                <p>Read More</p>
              </a>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <h2>Useful Links</h2>
              <a href="/">
                <p>Home</p>
              </a>
              <a href="/about-us">
                <p>About Us</p>
              </a>
              <a href="/faqs">
                <p>FAQs</p>
              </a>
              <a href="/emi-calculator">
                <p>EMI Calculator</p>
              </a>
              <a href="/contact-us">
                <p>Contact Us</p>
              </a>
              <a href="/privacy-policy">
                <p>Privacy Policy</p>
              </a>
            </Col>
            <Col xs={12} sm={6} md={3}>
              <h2>Services</h2>
              <a href="/personal-loan">
                <p>Personal Loan</p>
              </a>
              <a href="/business-loan">
                <p>Business Loan</p>
              </a>
              <a href="/home-loan">
                <p>Home Loan</p>
              </a>
              <a href="/loan-against-property">
                <p>Loan Against Property</p>
              </a>
              <a href="/balance-transfer">
                <p>Lease Rental Discounting</p>
              </a>
              <a href="/working-capital">
                <p>Working Capital</p>
              </a>
              <a href="/working-capital">
                <p>Builder & Project Funding</p>
              </a>
            </Col>
          </Row>
          <hr />
          <Row>
            <Col xs={12} sm={6} className="text copyright">
              © - 
Copyright Angel Capital-Loan Aggregators&#8482;
 2019. All right
              reserved.
            </Col>
            <Col xs={12} sm={6} className="text-light socialMediaLinks">
              <a href="https://www.facebook.com/Angelcapitalloanaggregators/" rel="noopener noreferrer" target="_blank">
            <i className="fab fa-facebook-square"></i>
            </a>
            <a href="https://www.linkedin.com/company/angel-capital-loan-aggregators/" rel="noopener noreferrer" target="_blank">

            <i className="fab fa-linkedin"></i>
            </a>
            <a href="https://www.instagram.com/_angelcapital_/" rel="noopener noreferrer" target="_blank">

            <i className="fab fa-instagram"></i>
            </a>
            </Col>
          </Row>
        </Container>
      </Container>
    </div>
  );
};
