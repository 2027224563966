import React, { Component } from "react";
import { Row, Col, Button } from "reactstrap";
import EMICalculator from "./EMICalculator";

export default class EMICompare extends Component {
  state = {
    widgetCounter: 2
  };
  handleRemoveWidget = () => {
    let widgetCounter = this.state.widgetCounter;
    if (widgetCounter > 2) widgetCounter -= 1;
    this.setState({ widgetCounter: widgetCounter });
  };

  handleAddWidget =()=>{
    this.setState({ widgetCounter: this.state.widgetCounter + 1 });
  };
  render() {
    let widgetsArray = [...Array(this.state.widgetCounter)].map(
      (value, index) => index + 1
    );
    let widgets = widgetsArray.map((val, index) => (
      <Col sm={6} key={index}>
        <EMICalculator size="full" />
      </Col>
    ));
    return (
      <Row> 
        <Col xs={6} className="text-center mb-5">
          <Button
            color="primary"
            type="button"
            onClick={this.handleRemoveWidget}
          >
            Remove Calculator
          </Button>
        </Col>
        <Col xs={6} className="text-center mb-5">
          <Button
            color="primary"
            type="button"
            onClick={this.handleAddWidget}
          >
            Add Calculator
          </Button>
        </Col>
        {widgets}
      </Row>
    );
  }
}
