import React from 'react';

const cellStyle = {
  padding: '15px 20px',

  margin: '0',
};

const spanStyle = {
  fontWeight: '700',
};

const displayDataCell = (label) => (
  <th xs={2} className="table-header" style={cellStyle}>
      <div style={spanStyle}>{label}</div>
  </th>
);

const AmortizationTableHeader = () => (
  <thead>
    <tr>
      <th style={{display: 'none', fontSize: '16px'}}>
          Angel Capital
      </th>
    </tr>
    <tr>
    <th style={{display: 'none', fontSize: '13px'}}>
        Amortization Schedule
        </th>
      
      </tr>
    <tr/>
    <tr/>

   <tr>
    {displayDataCell('Date')}
    {displayDataCell('Total')}
    {displayDataCell('Principal')}
    {displayDataCell('Interest')}
    {displayDataCell('Balance')}
   </tr>
  </thead>
);

export default AmortizationTableHeader;