import {Finance} from 'financejs'
let finance = new Finance();

export default function (principal, tenure, interestRate) {
    const monthlyPayment = finance.AM(principal, interestRate, tenure, 1);
    var monthlyRate = interestRate/12 / 100.0;
    var amortizationSchedule = [];
  
    for (var i = 0; i < (tenure); i++) {
      var prevPrincipal = i === 0 ? principal : amortizationSchedule[i-1].principalBalance;
      var interestPayment = prevPrincipal * monthlyRate;
      var principalPayment = monthlyPayment - interestPayment;
      var principalBalance = Math.max(prevPrincipal - principalPayment, 0);
      var accInterest = (i === 0 ? 0 : amortizationSchedule[i-1].accInterest) + interestPayment;
      amortizationSchedule.push({
        paymentNumber: i+1,
        payment: monthlyPayment,
        principalBalance: principalBalance,
        interestPayment: interestPayment,
        principalPayment: principalPayment,
        accInterest: accInterest,
        interestPaymentRounded: Math.round(interestPayment),
        principalPaymentRounded: Math.round(principalPayment),
        principalBalanceRounded: Math.round(principalBalance),
        accInterestRounded: Math.round(accInterest),
        paymentRounded: Math.round(monthlyPayment),

      });
    }
    return amortizationSchedule;
}