import React from "react";
import { Container, Row } from "reactstrap";
import {Helmet} from 'react-helmet'
import Services from "../components/service/Services";
import Hero from "../components/hero/Carousel";
import HelpingGrow from "../components/helpingGrow/HelpingGrow";
import HowItWorks from "../components/howItWorks/HowItWorks";
import ApplyNow from "../components/applyNow/ApplyNow";

const Home = () => {
  return (
    <div>
      <Helmet>
      <meta
    name="keywords"
    content="personal loan, business loan, builder and project funding, loan against property, lease rental discounting, working capital"
  />
      </Helmet>
      <Container fluid>
        <Row>
          <Hero />
        </Row>
      </Container>
      <Services
        isExpanded={false}
        mdWidth={3}
        serviceLength={4}
        viewAllLink={true}
      />
      <HelpingGrow />
      <HowItWorks />
      <ApplyNow/>
    </div>
  );
};

export default Home;
