import React, { Component } from "react";
import {
  Collapse,
  Navbar,
  NavbarToggler,
  NavbarBrand,
  Nav,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem
} from "reactstrap";
import Logo from '../../assets/images/angel-capital-logo-1.png';

class Header extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.handleScroll = this.handleScroll.bind(this);
    this.state = {
      isOpen: false,
      navClass: 'transparent'
    };
  }
  toggle() {
    this.setState({
      ...this.state,
      isOpen: !this.state.isOpen
    });
  }
  
  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll, true);
  };
  
  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  };
  
  handleScroll(event) {
    if (window.scrollY > 300){
      this.setState({
        ...this.state,
        navClass: 'scrolled'
      });
    } else {
      this.setState({
        ...this.state,
        navClass: 'transparent'
      });
     
    }
  };
  render() {
    return (
      <div className='header'>
        <Navbar  dark expand="md" className={`nav-style fixed-top ${this.state.navClass}`}>
          <NavbarBrand href="/"><img src={Logo} className='img-fluid' alt='Angel Capital'/></NavbarBrand>
          <NavbarToggler onClick={this.toggle} />
          <Collapse isOpen={this.state.isOpen} navbar>
            <Nav className="ml-auto" navbar>
              <NavItem>
                <NavLink href="/">HOME</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/about-us">ABOUT US</NavLink>
              </NavItem>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle nav caret>
                  LOAN SERVICES
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem>
                    <NavLink href="/personal-loan">PERSONAL LOAN</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/business-loan">BUSINESS LOAN</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/home-loan">HOME LOAN</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/loan-against-property">LOAN AGAINST PROPERTY</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/lease-rental-discounting">LEASE RENTAL DISCOUNTING</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/working-capital">WORKING CAPITAL</NavLink>
                  </DropdownItem>
                  <DropdownItem>
                    <NavLink href="/builder-and-project-funding">BUILDER & PROJECT FUNDING</NavLink>
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavItem>
                <NavLink href="/faqs">FAQS</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href="/contact-us">CONTACT US</NavLink>
              </NavItem>
              <NavItem>
                <NavLink href='/emi-calculator'>EMI CALCULATOR</NavLink>
              </NavItem>
            </Nav>
          </Collapse>
        </Navbar>
      </div>
    );
  }
}
export default Header;
