import React from "react";
import Data from "../../data";
import ServiceCard from "./ServiceCard";
import { Container, Row, Col } from "reactstrap";
const categoryList = Object.keys(Data.loanCategories);

export default function Services(props) {
  const serviceCardList = categoryList
    .map(categoryItem => {
      const category = Data.loanCategories[categoryItem];

      return (
      <Col xs={12} md={props.mdWidth || 4} key={category.href}>
        <ServiceCard
          title={category.name}
          isExpanded={props.isExpanded}
          cardText={category.cardText}
          href={category.href}
          cardImage={category.image}
        />
      </Col>
    )})
    .slice(0, props.serviceLength || categoryList.length);
    console.log(serviceCardList)

  return (
    <Container className="services-cards">
      <Row>
        <Col xs={12} className="text-center mt-5 mb-5">
          <h2>Explore Our Services</h2>
          <Col xs={12} className="text-center mt-2 mb-2">
            <h4>What We Offer Explore Our Services</h4>
          </Col>
        </Col>
      </Row>
      <Row
        className="wow fadeInLeft"
        data-wow-offset="50"
        data-wow-delay="0.5s"
      >
        {serviceCardList}
      </Row>
      {!!props.viewAllLink ? (
        <Row>
          <Col>
            <div className="mt-5 text-center">
              <a href="/services" className=" view-all-servies btn">
                View All Services
              </a>
            </div>
          </Col>
        </Row>
      ) : null}
    </Container>
  );
}
