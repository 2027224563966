import React, { Fragment, Component } from "react";
import AmortizationTableRow from "./AmortizationTableRow";
import AmortizationTableHeader from "./AmortizationTableHeader";
import { UncontrolledCollapse, Button } from "reactstrap";
import XLSX from 'xlsx';

class AmortizationTable extends Component  {
  state = {
    tableData: [],
  }

  handleExcelDownload = () => {
    var workbook = XLSX.utils.table_to_book(document.getElementById(`${this.props.tableId}`));
    XLSX.writeFile(workbook, `Ammortization-AngelCapital-${this.props.tableId}.xlsx`)
  }
  render () {
    const props = this.props;
  return (
    <Fragment>
      <Button
        color="primary"
        id={`table-${props.tableId}`}
        style={{ marginBottom: "1rem", marginTop: "1rem" }}
      >
        Show Amortization Schedule
      </Button>
    
    
      <UncontrolledCollapse toggler={`#table-${props.tableId}`}>
        <button className="btn btn-primary download-as-excel" onClick={this.handleExcelDownload}>Download Table</button>
        <div style={{ overflowX: "auto", margin: "auto", padding: "0px 2px" }}>
          <table className="amortization-table" id={props.tableId}>
            <AmortizationTableHeader />
            <tbody>
              {props.input.amortization.map((row, index) => (
                <AmortizationTableRow
                  input={props.input}
                  row={row}
                  key={index}
                />
              ))}
            </tbody>
          </table>
        </div>
      </UncontrolledCollapse>
    </Fragment>
  );
}
};

export default AmortizationTable;
