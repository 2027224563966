import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "../components/PageHeader/PageHeader";
import { Nav, NavItem, NavLink, TabPane, TabContent } from "reactstrap";
import EMICalculator from "../components/calculators/EMICalculator";
import AffordabilityCalculator from '../components/calculators/AffordabilityCalculator';
import EMICompare from '../components/calculators/EMICompare';

export default class ContactUs extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: "1"
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab
      });
    }
    console.log(this.state, tab);
  }
  render() {
    return (
      <div className="emi-calculator">
        <PageHeader title="EMI Calculator" sectionClass="personal-loan" />

        <Container className="padding-top-50 text-center">
          <Row>
            <Col xs={12}>
              <Nav tabs justified className="mb-5">
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "1" ? "active" : null}
                    onClick={() => {
                      this.toggle("1");
                    }}
                  >
                    EMI Calculator
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "2" ? "active" : null}
                    onClick={() => {
                      this.toggle("2");
                    }}
                  >
                    Affordability Calculator
                  </NavLink>
                </NavItem>
                
                <NavItem>
                  <NavLink
                    className={this.state.activeTab === "3" ? "active" : null}
                    onClick={() => {
                      this.toggle("3");
                    }}
                  >
                    Compare EMI
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={this.state.activeTab} className="mt-5">
                <TabPane tabId="1">
                    <EMICalculator/>
                </TabPane>
                <TabPane tabId="2"><AffordabilityCalculator/></TabPane>
                <TabPane tabId="3"><EMICompare/></TabPane>
              </TabContent>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
