import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import PageHeader from "../components/PageHeader/PageHeader";
import ApplicationForm from '../components/forms/ApplicationForm';

export default class ContactUs extends Component {
  render() {
    return (
      <div className="contact-us">
        <PageHeader title="Application Form" sectionClass="personal-loan" />

        <Container className='padding-top-50 text-center'>
          <Row>
            <Col className="text-center mb-5">
              <h3>Please Fill the form</h3>
            </Col>
            <Col xs={12} md={12} className="mb-5">
              <ApplicationForm />
            </Col>
            <Col className="text-center mb-5">
              <h3>Reach out to our Office</h3>
            </Col>
          </Row>
        </Container>
        <iframe
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d15071.042671452797!2d72.8729471633743!3d19.205652954085906!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b7f729fd0319%3A0xc246833a07d3e983!2sAngel+Capital+-+Loan+Aggregators!5e0!3m2!1sen!2sin!4v1554632967220!5m2!1sen!2sin"                
                width="100%"
                height="550"
                frameBorder="0"
                style={{ border: 0, marginBottom: '-110px'}}
                allowFullScreen
                title="Angel Capital"
              />
      </div>
    );
  }
}
