import React from "react";
import { Container, Row, Col, Card, CardTitle, CardText } from "reactstrap";
import PageHeader from "../components/PageHeader/PageHeader";
import AboutUsImage from "../assets/images/about-us.jpg";
import Vikram from "../assets/images/vikram.jpg";
import VikramGray from "../assets/images/vikram-black.jpg";

import Mayur from "../assets/images/mayur.jpg";
import MayurGray from "../assets/images/mayur-black.jpg";

import AboutMayur from "../assets/images/mayur-about1.jpg";
import AboutRight from "../assets/images/about-right1.jpg";





export default () => {
  return (
    <div className="about-us">
      <PageHeader title="About Us" sectionClass="personal-loan" />
      <Container className="padding-top-50">
        <Row className="mb-5">
          <Col xs={12} sm={6} className="wow slideInLeft">
            <h3>About Angel Capital</h3>
            <p className="text">
              Established in December 2018, we are the fastest-growing organization in the loan aggregation space in India. Tired
              of endless bank visits and fake phone commitments, we at Angel
              Capital provide you with financial advisory. Led by
              passionate problem-solvers and backed by advisors with a decade+ years of
              experience in finances and investment. With our online aggregator
              services we can help you achieving your perception we understand
              that every customer need is different and every customer has a
              different priority.
            </p>
            <p className="text">
              Angel Capital is changing the Loan marketplace in India by giving
              borrowers the power to choose and tailor services as per their needs
              and requirements.
            </p>
            <p className="text">
              Our expert advisors are always available to help you right till
              the loan disbursal. In accordance with our top notch expertise,
              and over 45+ investing partners, we bring such a vast range of
              options to our customers. So whether it’s your dream home,
              business goals or your dream car, trust us to get it done, right.
            </p>
            <h5>
              <a href="/services">Check Our Services </a>
            </h5>
          </Col>
          <Col xs={12} sm={6} className="wow slideInRight">
            <img
              src={AboutRight}
              alt="About Angel Capital"
              className="img-fluid float-right"
              style={{marginTop: '90px'}}

            />
          </Col>
        </Row>
        <Row className="mt-5">
          <Col xs={12} sm={6} className="wow slideInLeft">
            <img
              src={AboutMayur}
              alt="About Angel Capital"
              className="img-fluid float-left"
            />
          </Col>
          <hr/>
          <Col xs={12} sm={6} className="founder wow slideInRight">
            <h3> Mayur Surti</h3>
            <h5>Founder & Director</h5>
            <br/>
            <p className="text">
              Mayur brings to Angel Capital over a decade of experience in
              various sectors right from Banking, Consulting and Venture
              Capital. He started his banking career with Andromeda Sales where
              he worked as Sales Manager and structured products like secured,
              unsecured and working capital loans to HNI clients with disbursements above 500 crores plus (Individually & along with Jointly Associate of Team) till date.
            </p>
            <p className="text">
              He has diverse experience in roles ranging from Vice President at LenDenClub (P2P Funding). Now being
              a <strong>Founder and Director</strong> of Angel Capital. He brings vast
              understanding of the Finance landscape to leading and investment
              platform.
            </p>
          </Col>
        </Row>
      </Container>
      <Container fluid className="about-us-details">
        <Row>
          <Col xs={12}>
            <Container>
              <Row>
                <Col xs={12} sm={6} className="wow slideInLeft">
                  <p className="title">Our Mission and Vision</p>

                  <br />
                  <h2>
                    Helping loan seekers to make proper decisions using today's
                    technology and guide them on a path
                    <span> for a better tomorrow</span>
                    <br />
                    <br />
                  </h2>
                  <p className="text">
                    At Angel Capital, we power your dream by initiating change
                    for the best. Our vision is to help our customers to live
                    their world better. We are on a mission to simplify
                    everything, so that you can focus on your loved ones and
                    things that matter the most. We are here to handle all the
                    challenge, find solutions and deliver the best to you with
                    optimism, innovation, transparency, and advancement. Get the
                    best deal with Angel Capital.
                  </p>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
      <Container className="padding-top-50">
        <Row>
          <Col xs={12} className="section-title">
            <h2>Our Expert Team</h2>
            <p className="subheading">We are here for your Help</p>
          </Col>
          <Col sm={6} className="wow slideInLeft">
            <Card body>
              <Row className="profile-details">
                <Col xs={12} sm={4} className="mayur-profile">
                  <img
                    src={MayurGray}
                    className="img-fluid first-visible-mayur"
                    alt="Mayur Surti"
                  />
                  <img
                    src={Mayur}
                    className="img-fluid second-visible-mayur"
                    alt="Mayur Surti"
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <CardTitle>
                    <strong>Mayur Surti</strong>
                  </CardTitle>
                  <CardText>
                  Experienced Professional in driving revenue and building successful Customer Relationships with HNIs and Ultra HNIs across the Secured and Unsecured Lending Space.</CardText>
                  <hr />
                  {/* <a href="www.twitter.com">
                    <i className="fab fa-twitter-square" />
                  </a> */}
                  <a href="https://www.linkedin.com/in/mayur-surti/" target="_blank" rel="noopener noreferrer">
                    <i className="fab fa-linkedin" />
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
          <Col sm={6} className="wow slideInRight">
            <Card body>
              <Row>
                <Col xs={12} sm={4} className="vikram-profile">
                  <img
                    src={VikramGray}
                    className="img-fluid first-visible"
                    alt="Vikram Avate"
                  />
                   <img
                    src={Vikram}
                    className="img-fluid second-visible"
                    alt="Vikram Avate"
                  />
                </Col>
                <Col xs={12} sm={8}>
                  <CardTitle>
                    <strong>Vikram Avate</strong>
                  </CardTitle>
                  <CardText>
                    Experienced Divisional Manager with a demonstrated history of working with multiple organizations across the Secured and Unsecured Lending Space in the Financial Industry.
                  </CardText>
                  <hr />
                  {/* <a href="www.twitter.com">
                    <i className="fab fa-twitter-square" />
                  </a> */}
                  <a href="https://www.linkedin.com/in/vikram-avate-2518a4151/">
                    <i className="fab fa-linkedin" />
                  </a>
                </Col>
              </Row>
            </Card>
          </Col>
        </Row>
      </Container>
      {/* <Container fluid className="team-pan-photo">''</Container> */}
    </div>
  );
};
