import React from 'react';
import PageHeader from '../../../components/PageHeader/PageHeader';
import ServiceDetail from '../../../components/services/ServiceDetail';
import Faqs from '../../../components/faq/Faqs';
import Data from '../../../data';
import ApplyNow from '../../../components/applyNow/ApplyNow';
// import {Container, Row, Col} from 'reactstrap';

const loanData = Data.loanCategories['businessLoan'];
const faqs = Data.faqs.businessLoan.questions.slice(0,4);

export default (props) => {
  return (
    <div>
      <PageHeader sectionClass='personal-loan' title='Business Loan'/>
      <ServiceDetail serviceTitle={loanData.name} serviceDetail={loanData.paragraphTop}  details={loanData}/>
      <Faqs items={faqs}/>
      <ApplyNow/>
    </div>
  )
}
