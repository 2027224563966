import React, { Component, Fragment } from "react";
import { Col, FormGroup, FormText, Input } from "reactstrap";

export default class LeaseRentalDiscounting extends Component {
  render() {
    return (
      <Fragment>
           <Col sm={6}>
          <FormGroup>
            <Input
              type="number"
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              value={this.props.values.propertyValue}
              name="propertyValue"
              placeholder="Property Value"
              required
            />
            <FormText color="danger">
              {this.props.errors.propertyValue &&
                this.props.touched.propertyValue && (
                  <div>{this.props.errors.propertyValue}</div>
                )}
            </FormText>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Input
              type="text"
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              value={this.props.values.propertyLocation}
              name="propertyLocation"
              placeholder="Property Location"
              required
            />
            <FormText color="danger">
              {this.props.errors.propertyLocation &&
                this.props.touched.propertyLocation && (
                  <div>{this.props.errors.propertyLocation}</div>
                )}
            </FormText>
          </FormGroup>
        </Col>
        <Col sm={6}>
          <FormGroup>
            <Input
              type="number"
              onChange={this.props.handleChange}
              onBlur={this.props.handleBlur}
              value={this.props.values.rentReceived}
              name="rentReceived"
              placeholder="Rent Received (Per Month)"
              required
            />
            <FormText color="danger">
              {this.props.errors.rentReceived &&
                this.props.touched.rentReceived && (
                  <div>{this.props.errors.rentReceived}</div>
                )}
            </FormText>
          </FormGroup>
        </Col>
       
      </Fragment>
    );
  }
}
