import React from 'react';
import Services from '../../components/service/Services';
import PageHeader from '../../components/PageHeader/PageHeader';


export default function ServicesList() {
  return (
    <div>
      <PageHeader title="Our Services" sectionClass="personal-loan"/>
        <Services  isExpanded={true}/>
    </div>
  )
}
