import React from 'react';
import {Container, Row, Col} from 'reactstrap';

const ApplyNow =  () => {
  return (
    <Container className="apply-now mt-5" fluid>
    <Row>
        <Col xs={12}>
        <Container>
        <Row>
            <Col xs={12} className='text-center'>
            <h1>Looking for a Loan ?</h1>
            <h2>We'll help you save days of confusion and stress</h2>
            </Col>
            <Col xs={12} className='text-center'>
            <a  href='/apply-now' className="btn btn-primary mt-4">Apply Now</a>
            </Col>
        </Row>
        </Container>
        </Col>
    </Row>
        
    </Container>
  );
}
export default ApplyNow;