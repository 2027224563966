const content = {
  loanCategories: {
    personalLoan: {
      name: "Personal Loan",
      sectionClass: "personal-loan",
      href: "personal-loan",
      image: "personal-loan 2.jpg",
      cardText:
        "A personal loan is a unique financial product that helps you to meet unforeseen expenditures and fulfill any personal need. It can...",
      benefits: [
        "Instant money approval",
        "Flexible payback options",
        "Money transfer in 48 to 72 hours of sanction",
        "Paperless loan approval",
        "Easy EMI option",
        "Low interest rate",
        "No hidden charges",
        "No Additional foreclosure charges"
      ],
      paragraphTop: [
        "A personal loan is a unique financial product that helps you to meet unforeseen expenditures and fulfill any personal need. It can be availed by meeting the lender's eligibility criteria. Personal Loan to meet your financial requirements such as planning a vacation or your dream wedding, paying for your higher studies or home renovation. You can use a personal loan to cover the expenses of an emergency than other things."
      ],
      paragraphRight: [
        "We offer personal loans with instant approval and disbursal within 48 to 72 working hours. Borrow up to Rs.1 crore and above without the need to pledge any collateral. Only meet the basic eligibility criteria and submit your required basic documents to avail the money as per your need. Repay your loan with easy repayment options ranging from 12months to 60 months."
      ]
    },
    businessLoan: {
      name: "Business Loan",
      sectionClass: "personal-loan",
      href: "business-loan",
      image: "business-loan.jpg",
      cardText:
        "Business loans can be used for several requirements from small scale business to expansion,funding for your business is now 10 to 15 working days away...",
      benefits: [
        "Increase your business cash flow",
        "Scale up operations and take on bigger projects and much more",
        "Lease bigger office premises",
        "Purchase, lease or repair machinery and equipment",
        "Upgrade to the latest technology",
        "Stock up on inventory",
        "Purchase raw material for bulk orders",
        " Expand to another area or city"
      ],
      paragraphTop: [
        "Business loans can be used for several requirements from small scale business to expansion,funding for your business is now 10 to 15 working days away from you. Use the funds to invest in infrastructure, expand operations, upgrade to the latest plant and machinery, maintain inventory, or to increase working capital. These customized loans can give your business the much-needed boost to help your enterprise scale new heights with enhanced competitiveness and profitability."
      ],
      paragraphRight: [
        "Angel Capital valued business loans for your unique requirements. Maintain a healthy cash flow without any security and keep your business away from any financial roadblocks. Install or upgrade your plant and machinery or equipment to latest versions using our business loan and meet your bulkly orders smoothly. Small and medium enterprises can now grow their business and scale in an opterations in easy ways."
      ]
    },
    homeLoan: {
      name: "Home Loan",
      sectionClass: "personal-loan",
      href: "home-loan",
      image: "homeloan.jpg",
      cardText:
        "Home loans allow and assist people to purchase their dream house. At Angel Capital, we provide home loan up to...",
      benefits: [
        "Easy Balance Transfer Facility",
        "Top-up Loan",
        "Property Dossier",
        "Part-prepayment and Foreclosure Facility",
        "Flexible Tenor",
        "Minimal Documentation",
        "Online Account Management",
        "Customised Insurance Schemes",
        "No additional Foreclosure charges (for individual)"
      ],
      paragraphTop: [
        "Home loans allow and assist people to purchase their dream house. At Angel Capital, we provide home loan up to Rs.100 crore & above at lowest interest rate in India. if you're looking for your first home, or you want to build your own house, or if you just want to renovate your current home, our home loan offerings will meet all your requirements. Finance your requirements with a top-up loan over and above your existing housing loan."
      ],
      paragraphRight: [
        " Angel Capital one of the best Home Loan provider in India, offers easy housing finance solutions. With our attractive Home Loan policy along with low interest rates provides benefits to suit your finances. Our Home Loans are designed to help you get your dream house without hassle."
      ]
    },
    loanAgainstProperty: {
      name: "Loan against Property",
      sectionClass: "personal-loan",
      href: "loan-against-property",
      image: "loan-against-property.jpg",
      cardText:
        "Fulfill your dreams of financing your child's education, managing your wedding expenses, expanding your business or even handling...",
      benefits: [
        "High-value loans made affordable",
        "Hassle-free loan disbursal",
        "Flexible Tenor",
        "Easy balance transfer facility",
        "Flexi Hybrid feature",
        "Online Account Management"
      ],
      paragraphTop: [
        "Fulfill your dreams of financing your child's education, managing your wedding expenses, expanding your business or even handling unforeseen medical expenses by using our Loan Against Property. Money sanction takes 10 to 25 working days. Angel capital offers customized Loan Against Property to salaried and self-employed individuals."
      ],
      paragraphRight: [
        "You can easily apply for loan against property if you are salaried and age between 30-55 years and employee in an MNC, work in a private company or public sector. If you are self-employed meet criteria of age between 25 to 70 years and have to be an individual with regular source of income. Have to be resident of India."
      ]
    },
    workingCapital: {
      name: "Working Capital",
      sectionClass: "personal-loan",
      href: "working-capital",
      image: "working-capital-loan.png",
      cardText:
        "A working capital loan is a loan to fulfill a company's everyday operations. These loans are for long-term assets or investments...",
      benefits: [
        "No equity transaction required",
        "Management in owner's hands",
        "Risk free Capital",
        "Debt Financing",
        "Business owners efficiently cover any gaps of expenditures"
      ],
      paragraphTop: [
        "A working capital loan is a loan to fulfill a company's everyday operations. Money transferred in one month & above. These loans are for long-term assets or investments, instead are used to provide the working capital to covers a company's short-term operational needs such as payroll, rent and debt payments. In this way, working capital loans are simply corporate debt borrowings that are used by a company to finance its daily operations."
      ],
      paragraphRight: [
        "Sometimes a company does not have adequate cash on hand or asset liquidity to cover day-to-day expenses we here at Angel Capitals help you fulfil your daily cooperate needs to secure a loan for this purpose. Several companies with high seasonality or cyclical sales usually rely on working capital loans to help them reduced business activity. Manufacturers or operates with this type of seasonality often require a working capital loan to pay their wages and other operating expenses during the quiet period. The loan is usually repaid once company hits its busy season and no longer needs the financing."
      ]
    },
    leaseRentalDiscounting: {
      name: "Lease Rental Discounting",
      sectionClass: "personal-loan",
      href: "lease-rental-discounting",
      image: "lease-agreement.jpg",
      cardText:
        "Lease Rental Discounting (LRD) is a term loan offered against rental receipts derived from lease contracts with corporate tenants...",
      benefits: [
        "No equity transaction required",
        "Management in owner's hands",
        "Risk free Capital",
        "Debt Financing",
        "Business owners efficiently cover any gaps of expenditures"
      ],
      paragraphTop: [
        "Lease Rental Discounting (LRD) is a term loan offered against rental receipts derived from lease contracts with corporate tenants. The loan is provided to the lesser based on the discounted value of the rentals and the underlying property value. It is another method to obtain finance from bank or other financial institute or lending institutes. Lease Rent Discount (LRD) consideration is between the borrower who owns the premises, the tenant who has rented the said premises or taken on lease and the bank or financial institute or Corporate. Loan is sanctioned in 10 to 25 working days. The rent is considered as fixed income over a stipulated time i.e. Lease or rent period or tenure. The agreement is between the borrower and lender and the major term of repayment is the rent which is directly deposited with the lender and not with the borrower. The Borrower is sanctioned a loan based upon the rent to be collected over the period of lease."
      ],
      paragraphRight: [
        "Sometimes a company does not have adequate cash on hand or asset liquidity to cover day-to-day expenses we here at Angel Capitals help you fulfil your daily cooperate needs to secure a loan for this purpose. Several companies with high seasonality or cyclical sales usually rely on working capital loans to help them reduced business activity. Manufacturers or operates with this type of seasonality often require a working capital loan to pay their wages and other operating expenses during the quiet period. The loan is usually repaid once company hits its busy season and no longer needs the financing."
      ]
    },
    builderAndProjectFunding: {
      name: "Builder & Project Funding",
      sectionClass: "personal-loan",
      href: "builder-and-project-funding",
      image: "builder-and-project.jpg",
      cardText:
        "Banking and non-banking lenders are offering loans for New constructions as real Estate is India's rapidly developing business segment...",
      benefits: [
        "Quick turnaround time.",
        "Professional & transparent approach.",
        "Customized offerings to suit customer needs.",
        "Flexibility in tenor and repayment options.",
        "Extensive experience of Angel Capital's Advisory."
      ],
      paragraphTop: [
        "Banking and non-banking lenders are offering loans for New constructions as real Estate is India's rapidly developing business segment and multiple financial institutions. Loan is given to construct or develop a new real estate project including both residential as well as commercial is known as Construction Loan under Project finance department. This construction finance can be used by an individual or a firm or company engaged in the business of real estate development or construction (Builder) under Project finance / loan department. Project finance / Construction Finance is the long term financing of infrastructure based upon the projected cash flows of the project rather than the balance sheets of the project sponsors. Funding takes one month & above for sanction."
      ],
      paragraphRight: [
        "There are specialized in Arrangement of Project Finance to Builders & Developers through banks and financial institution which builder and project funding has developed through trust and experience over decades. Customizing our services to your unique requirements, we will develop and execute a financing that will maximize the economic value of your project. Project finance is the long term finance based upon the projected cash flow of the project. Builder and project funding offers customize service to customers according to their requirements based upon their projects and experience."
      ]
    }
  },

  faqs: {
    angelCapital: {
      section: "angel-capital",
      questions: [
        {
          question: "What is an EMI? ",
          answer:
            "Equated Monthly Instalments (EMIs) give you the ease and benefit of paying back the loan amount in smaller convenient instalments. The instalments consist of both the principal and interest amounts."
        },
        {
          question:
            "How will my Equated Monthly Installment (EMI) be calculated?",
          answer:
            " Your EMI consists of two parts—paying back the principal amount you borrowed, plus the interest rates charged on it. Three factors come into the equation—how much you borrowed, the rate of interest, and the loan tenure. There are ways to bring your EMI down: for one, it drops automatically if there is a decrease in interest rates, or if you pay back more than you need to (called a partial prepayment)"
        },
        {
          question:
            "How will be balance transfer and top-up money be transferred to my loan account?",
          answer:
            "Balance transfer for every Loan will be done by payee cheque and for fresh top-up there will be an electronic transfer"
        },
        {
          question:
            "What is the minimum and maximum loan amount that I can get from Angel Capital?",
          answer:
            "We offer a minimum loan amount of Rs 30 thousand in personal loan to 200 crore across various products."
        },

      ]
    },
    personalLoan: {
      section: "personal-loan",
      questions: [
        {
          question: "What is personal loan?",
          answer:
            "A personal loan is a unique financial product that helps you to meet unforeseen expenditures and fulfill any personal need. It can be availed by meeting the lender's eligibility criteria. Personal Loan to meet your financial requirements such as planning a vacation or your dream wedding, paying for your higher studies or home renovation. You can use a personal loan to cover the expenses of an emergency than other things."
        },

        {
          question: "What can I use a personal loan for?",
          answer:
            "A personal loan can be used for almost any type of expense ranging from big ticket appliance purchases and home renovations to luxury vacations and debt consolidation. Some other cases where personal loans may be useful include payment to unexpected medical bills, investment in business, fixing your car, down payment of new house and much more"
        },
        {
          question: "Am I eligible for personal loan?",
          answer:
            "You must have a regular source of income to avail a personal loan whether you are a salaried individual or a professional. A person's eligibility is also affected by the company he/she is employed with, his/her credit history his/her residential location, his/her running or existing loans as obligation and other factors as per the lender’s criteria."
        },
        {
          question:
            "Is there a minimum personal loan amount that I need to borrow?",
          answer:
            "Yes. Though the exact amount of the minimum personal loan amount varies from one lending institution to another, most lenders have set their minimum personal loan principal amount at Rs. 1,00,000."
        },
        {
          question: "How is the maximum loan amount decided?",
          answer:
            "When calculating the maximum personal loan amount in case of salaried people, the bank/financial institution takes care that the EMI does not exceed 40% to 60% of the applicant’s take home salary.  Any existing loans that are being serviced by the applicant are also considered when calculating the personal loan amount. And, for the self employed, the loan value is determined on the basis of the profit earned as per the most recent acknowledged Profit/Loss statement, while taking into account any additional liabilities (such as current loans for business etc.) that the applicant might have."
        },
        {
          question: "What is the tenure of a personal loan?",
          answer:
            "Personal loans feature tenure of 1 year to 6 years or 12 to 72 months. In rare cases, shorter or longer personal loan tenures may be allowed by the lender on a case by case basis."
        },
        {
          question: "Can I apply for a personal loan jointly with my spouse?",
          answer:
            "Yes, personal loan can be applied either by yourself (singly) or together with a co-applicant (jointly).The co-applicant needs to be a family member like your spouse or parents and needs to be a working individual.  By getting a co-borrower, your loan application will be processed in a higher income bracket, enabling you to avail a larger loan amount. However, keep in mind that if either you or your co-applicant have poor credit history, the chances of success of your loan application may be adversely affected."
        },
        {
          question:
            "What are the key documents required when applying for a personal loan?",
          answer: `Though the documentation requirements of personal loans vary from one financial institution to another, some of the key documents that you would need to provide with your personal loan application include:
            <ul>
            <li> Two Photographs </li>
            <li> Copy of PAN Card </li>
            <li> Copy of Aadhaar Card </li>
            <li> Address Proof (if it’s your own house require any 2 of mentioned documents  Electricity bill, Ration card, Voter ID, Passport, Driving License) (if on rent require Rent agreement or Mobile Bill or Owners electricity bill mandatory)</li>
            <li>Income proof (Latest 3 month Salary Slip)</li>
            <li>Latest 3 month Bank Statement</li>
            <li>Appointment letter of current company (if current company work experience is less than 1 year than previous companies relieving letter will also be required)</li>
            
            </ul>`
        },
        {
          question: "What is the foreclosure charge for Personal loan?",
          answer:
            "Foreclosure charges vary from lender to lender from 1 to 5 percent for personal loan. If paid using own funds by the borrower than it will be zero percent after 1 year of loan disbursement."
        },
        {
          question: "What are the key steps in the loan approval process?",
          answer:
            "Approval of loan is at the sole discretion of the loan sanctioning officer who bases his/her decision on the basis of the criteria specified by the bank/ financial institution. The entire process can take from about 48 hours to 2 months (varies product to product) to about two week’s time. Once all the necessary documents are submitted and the verification process is completed, the loan, if sanctioned, is disbursed within seven working days by the bank. In order to avoid delays in loan processing and disbursement, do keep all necessary documents ready along with the post dated cheques (PDC) and/or signed Electronic Clearing System form."
        },
        {
          question: "What is average disbursal time for personal loan?",
          answer:
            "The entire Loan Disbursement process can take from 24 hrs to 7 working days."
        },
        {
          question: "What are the interest rates for personal loans?",
          answer:
            "Being unsecured loans, personal loans feature a higher rate of interest as compared to secured loans such as home loans and car loans. At present, many leading banks and NBFCs in India are offering personal loans at interest rates as low as 10.75 % to 16 % (varies case to case). However, the rate of interest applicable to a specific applicant would be contingent on key factors including credit score, income level, loan amount and tenure, previous relationship (savings account, loans or credit cards) with the lender as well as other factors."
        },
        {
          question: "How do I get the lowest interest rates on personal loan?",
          answer:
            "Earlier, you might have had to go to multiple lenders in order to get the information regarding interest rates, tenures, processing fees etc. Not anymore. Just log on to AngelCapital.com and fill out the simple form in our Personal Loan sections and in seconds you get information regarding numerous prospective lenders along with the applicable interest rates and various charges including processing fees. You can easily choose the lowest interest rate from the available list and apply for a personal loan within minutes from the convenience of your home or workplace"
        },
        {
          question:
            "Is there any extra charge payable when applying for personal loan?",
          answer:
            "Yes. In addition to the interest payable on the principal amount, there is an additional non-refundable charge - Processing fees, which is usually 1%-4% of the loan principal(waived off if client is Ultra HNI-Salaried) charged by the lender to take care of any paperwork that needs to be processed as part of the personal loan application process. This charge may be waived by your lender in case you have a long term association with the lender."
        },
        {
          question: "Are personal loan interest rates fixed or floating?",
          answer:
            "In case of a fixed rate personal loan, your interest amount and the principal remains fixed therefore every month during the loan tenure, you will pay the exact same amount as interest. In case of a floating rate personal loan, the emi amount will be fixed but the interest will keep changing accordingly on a personal loan. In case of a floating rate, the applicable interest rate may be varied by the bank periodically as per the new MCLR rules, floating interest rates may be changed either on a half yearly or yearly basis."
        },
        {
          question: "How is the personal loan disbursed?",
          answer:
            "Once you get approved for a personal loan, you may either receive an account payee cheque/draft equal to the loan amount or get the money deposited automatically into your savings account electronically."
        },
        {
          question: "What if I default on my scheduled Personal Loan EMIs?",
          answer:
            "In case you miss your scheduled personal loan EMIs and are unable to make future payments on your personal loan, the lender initially make attempts to recover the due amount through settlements and recovery agents. If such attempts fail and your loan account is marked as a default, this personal loan will show up on your credit report as a default, which will adversely affect your credit score and make it difficult for you to get approval for loans and credit cards in the future."
        },
        {
          question: "Are there any tax benefits associated with personal loan?",
          answer:
            "Personal loans usually have no tax benefits, however, in case you take a personal loan for home renovations/down payment, you may be eligible for income tax deduction under section 24. However, this tax benefit is limited to only the interest on the loan and not the principal amount. Also you would have to furnish proper receipts to claim deduction."
        },
        {
          question: "How to repay the personal loan?",
          answer:
            "The loan can be repaid in the form of Equated Monthly Installment (EMI) via post-dated cheques   drawn in favour of the bank or by releasing a mandate allowing payment through the Electronic Clearing Services (ECS) system."
        },
        {
          question:
            "How is personal loan different from loan against credit card?",
          answer:
            "Loan on credit card is an offer that you may be able to avail on your credit card. In many ways a loan offered on a credit card is similar, especially in terms of the interest rate and the loan tenure. However, a loan on credit card is only applicable to specific credit cards and you cannot approach any company other than your credit card issuer for a loan on credit card. In case of a personal loan, you can approach any lender for the loan. Moreover, credit card loans do not require any additional documentation unlike a personal loan application"
        },
        {
          question: 
            "What is the processing fee for Personal Loan?",
          answer: 
            "Processing fees for personal Loan varies between 0 to 5 percent dependent on the lender"
        }
      ]
    },
    businessLoan: {
      section: "business-loan",
      questions: [
          {
            question: "What is Business loan?",
            answer:
              "Business loans can be taken by small scale business to expansion,funding for your business. Use the funds to invest in infrastructure, expand operations, upgrade to the latest plant and machinery, maintain inventory, or to increase working capital. These customized loans can give your business the much-needed boost to help your enterprise scale new heights with enhanced competitiveness and profitability."
          },
        {
          question: "Why should I take a Business loan?",
          answer:
            "Whether your business is at an initial stage or in the growth phase, additional finance can help you keep up the momentum. You can avail a business loan from Angel Capital for your short or long term financial needs, to avoid any type of working capital shortfall."
        },
        {
          question:
            "What are the key documents required when applying for a Business loan?",
          answer: `<ul>
              <li>2 photographs</li>
              <li> Copy of PAN Card of the Company and the owner</li>
              <li> Copy of Aadhar Card of the Owner</li>
              <li> Address Proof</li>
              <li> Business Proof (Gumastha, GST certificate) </li>
              <li> Latest 3 years ITR- full set(Company and 51% Holding Director)</li>
              <li> Latest 1 year update bank statement(Company and 51% Holding Director)</li>
              <li> Memorandum of Association (MOA) and Articles of Association (AOA)</li> 
              <li> List of Director and shareholding partners as on date (Pvt. Ltd. Co.)</li> 
              <li> Business address proof (any utility bill)</li>
              <li> Any loans sanction letter or closure letter (latest 1 year)</li>
              <li> Note: Additional documents may be required depending on bank to bank</li>
          </ul>
          `},
          {
              question: "What is average disbursal time for Business loan?",
              answer:
              "The entire disbursal process may take upto 10-15 working days."
          },
          {
            question: "What are the loan tenure options for business loan?",
            answer:
              "The repayment tenure of business loan is from 1 - 5 years, depending on the repaying capability of the loan applicant."
          },
          {
              question: "What are the Foreclosure charges for business loan?",
              answer:
              "Foreclosure charges may vary lender to lender mostly between 1-5%."
          },  
          {
              question: "What is processing fee for Business loan?",
              answer:
              "The processing fees on Business Loan varies from 1% to 5% depending on lender."
          },
          {
              question: "What if I default on my scheduled Business loan EMI?",
              answer:
              "In case you miss your scheduled business loan EMIs and are unable to make future payments on your loan, the lender initially make attempts to recover the due amount through settlements and recovery agents. If such attempts fail and your loan account is marked as a default, this business loan will show up on your credit report as a default, which will adversely affect your credit score and make it difficult for you to get approval for loans and credit cards in the future."
          },
          {
              question: "What are the tax benefits of Business loan?",
              answer:
              "Business loans usually have no tax benefits, however,you may be eligible for income tax deduction under section 24. If this tax benefit is limited to only the interest on the loan and not the principal amount. Also you would have to furnish proper receipts to claim deduction."
            },
        {
          question: "Can the credit limit for a Business loan be increased?",
          answer:
            "Yes, it is possible. This is subject to the eligibility criteria at the time of your request, and may be done at the sole discretion of Angel Capital. You may submit a request letter to us, and if requested, fresh documents for the enhanced amount application. Angel Capital will source the file to the present lender for top up or change the lender and do Balance Transfer and Top Up "
        },
        {
          question: "How does a line of credit work?",
          answer:
            "Line of Credit is a unique credit facility given to our loan applicants, wherein you are approved for certain limit of credit/ loan, for a said duration. The monthly instalment for Line of Credit will consist only of the interest amount and will not have a principal component on a monthly basis. The principal amount of loan is to be repaid at the end of the tenure of the facility. You can deposit the funds when in access and withdraw fund when required in business, and you will be charged interest only on the amount utilized by you. The limit may drop along the tenor (dropline) or remain constant throughout the tenor, giving you maximum availability of funds throughout the tenor."
        },
        {
          question: "How do I calculate the interest on the line of credit?",
          answer:
            "It’s pretty simple. Here is a formula you can use: Interest= (Amount Used * Rate of Interest)/360* Number of days’ funds used. What is the difference between Line of Credit and a Term Loan? Term Loan : Customer avails loan and repays in equated instalments. There is normally an option to prepay with surplus funds, but no withdrawal options. Repayment of interest and principal is done every month. Line of Credit : Customer avails loan and uses the same as a FIXED credit line with renewal option every year. There is an option to prepay with surplus funds as well as withdraw within the available limit. There is repayment of interest only, every month, applicable on the utilised amount."
        },
        {
          question: "What is the Rate of Interest(ROI) charged on Business loans?",
          answer:
            "The ROI ranges between 12% to 19% and varies across different Lenders"
        },
        {
          question:
            "What are the Fees and Charges which I incur during Business Loan Processing?",
          answer: `Below are different types of fees and charges that are levied on a loan (only if applicable) <br/><strong>Interest rate</strong> applicable for Business and Professional Loans Customer varies basis the credit score which includes without limitations, a number of variables such as, customer details, loan delinquency and many more. These variables have been recognized as material risk explaining variables in company segmentation analysis. The aforesaid is dynamic and gets revised periodically as per the experience and performance of past portfolio and hence subject to change. 
          <br/><strong>BPI (Broken Period Interest)</strong> is applicable for cases disbursed post 15th of every month. BPI is calculated on pro-rata basis for the remaining days of the month from the date of disbursal this is so because the EMIs start from the second month of loan booking. The 1st month is considered as free period where no interest or EMI is charged from the customer. Processing fee this is the amount of fee charged for end to end processing of the customer’s loan application. Foreclosure charge is charged from the customers who wish to close the loan account before the completion of loan tenure.
          <br/><strong>Stamp Duty</strong> is a government charge, levied on customer as per the amount prescribed by the state government. Please note Stamp duty varies from state to state.
          <br/><strong>Goods& Service Tax</strong> is as applicable and prescribed by the government.
          <br/><strong>Bounce Charge</strong> is an amount charged as a penalty where the PDC(s) issued by the Customer is(are) not honoured by the bank, or ECS or any other Repayment Mode is(are) returned or not honoured.
          <br/><strong>Penal Interest</strong> is an additional interest payable by the Borrower to the Lender as a penalty in case of delay in payment of monthly installments.
          <br/><strong>Pre-payment</strong> Charge is a penalty for repayment of the principal amount, including the interest on the principal amount, of the Loan, either in part or whole, before its scheduled repayment date.
          <br/><strong>Processing Fees</strong> applicable between 1% to 5% depending on the lender and varies case to case.`
        },
        {
          question: "For what purposes Business loan can be availed?",
          answer:
            "Business loans can be availed for small or large scale businesses. If you are willing to start a new business, expanding the existing business, buy machinery and other capital goods, expand the business operations and purchase equipment you can apply for business loan from your preferred provider"
        },
        {
          question: "What are the types of Business loans in India?",
          answer:
            "There are different kinds of business loans, some are government based and others are private ones. Some common business loans include - working capital loans, term loan, loan against property, loan against share, Pradhan Mantri Mudra Yojana, SIDBI, MSME loans, machinery loans, business loan for women etc. are some of the business loans in India."
        },
        {
          question: "Are there Business loans for women entrepreneurs?",
          answer:
            "Yes, women entrepreneurs too can avail business loan which are specifically designed for women. Most of the banks and non-banking financial companies offer women entrepreneur centric business loans. "
        }
      ]
    },
    homeLoan: {
      section: "home-loan",
      questions: [
        {
          question: "What is a Home Loan?",
          answer:
            "Home loan is a loan disbursed by a bank or financial institution (lender) to an individual specifically for buying a residential property. Here, the lender holds the title of property until the loan is paid back in full along with interest."
        },
        {
          question: "What is the tenure of a Home loan?",
          answer:
            "Home loans are long term borrowing instruments with a minimum tenure of 10-25 years and a maximum tenure of 30 years. The tenure offered to you for your home loan depends on the loan amount that is sanctioned to you by the lender along with other factors."
        },
        {
          question: "What is the Eligibility Criteria for a Home Loan?",
          answer:
            "Anyone — whether self employed or salaried individuals/professionals — with a regular source of income can apply for home loans. One must be at least 21 years old when the loan period begins and should not exceed an age of 65 years when the loan ends or at the time of superannuation. This is the generic home loan eligibility criteria and specifics such as the minimum and maximum age limits, minimum income level, etc. may differ from one lender to another."
        },
        {
            question: "What is the processing fees for a Home loan?",
            answer:
              "Processing fese for Home loan varies across different lenders and is generally upto 1%."
          },
        {
          question: "Why should I apply for a home loan on Angel Capital?",
          answer: `When you log on to the home loan section of Angel Capital, you can apply for a home loan in minutes by following a few key steps:
    <ul><li>Just choose and click to apply for the home loan with the correct details on the click of a button.</li>
    <li>	Subsequently your prospective lender will get in touch with you to get supporting documents and complete other formalities.</li>
    </ul>
    In short by applying through Angel Capital, the process of comparing and choosing home loans is simplified by saving you a lot of time and effort.
    `
        },
        {
            question: "What is an average disbursal time for Home loan?",
            answer:
              "The entire process takes upto 10-25 days."
          },
          {
            question: "What is the Rate Of Interest(ROI) for Home loan?",
            answer:
              "The ROI for home loan ranges between 8% to 12% depending on the lender and varies case to case"
          },
          {
            question: "What if I default my scheduled Home loan EMI?",
            answer:
              "In case you miss your scheduled loan EMIs and are unable to make future payments on your loan, the lender initially make attempts to recover the due amount through settlements and recovery agents. If such attempts fail and your loan account is marked as a default and property will be sealed by bank."
          },
  {
            question: "What documents are required for home loan?",
            answer:
              `<strong>For Salaried</strong>:
              <ul><li>2 photographs</li> 
              <li>Copy of PAN Card</li>
              <li>Copy of Aadhar Card</li> 
              <li>Address Proof (if its your own house require any 2 of mentioned documents  Eletricity bill, Ration card, Voter ID, Passport, Driving Licence) (if on rent require Rent agreement or Mobile Bill or Owners electricity bill), 
              <li>Latest 6 months salary slip, Latest 6 months updated bank statement with salary credit</li> 
              <li>Appointment letter of current company (if unemployeed for more 3 months than relieving letter along with appointment letter of past company</li>
              <li>Form 16(last 2 years)</li>
              </ul><br/>
              <strong>For Self-Employed</strong>:
                <ul><li>2 photographs</li> 
                <li>Copy of PAN Card</li> 
                <li>Copy of Aadhar Card</li> 
                <li>Address Proof</li>
                <li>Business Proof (Gumastha, GST certificate) Latest 3 years ITR - full set(Company and 51% Holding Director)</li>
                <li>Latest 1 year update bank statement(Company and 51% Holding Director)</li>
                <li>Memorandum of Association (MOA) and Articles of Association (AOA)</li>
                <li>List of Director and shareholding partners as on date (Pvt. Ltd. Co.)</li> 
                <li>Business address proof (any utility bill)</li>
                <li>Any loans sanction letter or closure letter (latest 1 year)</li></ul>
            Note: Additional documents may be required depending on bank to bank
            <strong>Initial Cheque for legal valuation of property amounting between Rs 5000 to Rs 10000 or above</strong>
            `
          },
          {
            question: "What are the Foreclosure charges for Home loan?",
            answer:
            "Foreclosure charges may vary lender to lender  0% if paid using own funds and primary applicant is an individual. If paid using balance transfer its mostly between 1% - 3%."
        }, 
  
        {
          question: "Can I apply for a joint loan with my friend?",
          answer:
            "No. A lender would only allow you to apply for a joint home loan if the application is co-signed by one or more members of your immediate family. Thus, your friend does not qualify."
        },
        {
          question: "How do I repay my home loan?",
          answer:
            "There are different ways to pay off your loan such as issuing post-dated cheques for the tenure of the home loan, getting the amount deducted automatically from your salary or by issuing standard instructions to the lender for ECS (Electronic Clearing System) wherein the EMI is automatically deducted from your bank every month."
        },
        {
          question: "What is floating rate home loan?",
          answer:
            "If the interest rate on the loan varies periodically over the loan tenure, then it is called a floating rate home loan. Lenders have their own base rate which determines the rate of interest charged on a home loan. The base rates of banks are revised from time to time based on RBI directives as well as other factors, which leads to an increase or decrease in the EMI amount payable."
        },
        {
          question: "What is a fixed rate home loan?",
          answer:
            "Fixed rate home loans are offered at a predetermined interest rate during the loan period and these remain unchanged during the loan period irrespective of market conditions. This can be a huge benefit when market volatility starts affecting interest rates. For instance, if the RBI increases interest rates on loans, then people with fixed rate home loan will not be affected by any increase or decrease in the market interest rates and the EMI amount will remain unchanged. This type of home loan is less popular these days."
        },
        {
          question: "Is there any tax benefit available on home loans?",
          answer: `The tax benefit on home loan is divided into two sections-<br/>
          <ul>
          <li>	Tax exemption on repayment of the home loan principal: This is the deduction allowed under Tax Section 80C with a maximum annual tax deduction of Rs, 150,000 under the section.</li>
          <li>	Tax benefit on the interest rate for home loan- Under Section 24 of the Income Tax Act, you can avail the tax benefit on the amount of interest paid on a home loan to the maximum limit of Rs. 2 lakhs for a self occupied property.</li></ul>
    Tax benefit for Joint Borrowers: In case of joint home loans, each of the co-borrowers is eligible to receive a total of Rs. 3.5 lakhs (1.5 lakhs under section 80C + 2 lakhs under section 24) as tax exemption. Hence, if a married couple co-signs for a home loan, they can claim a total tax exemption of Rs. 7 lakhs on their home loan.<br/> <strong>The exemption limit is dependent on the financial budget and may change accordingly</strong>`
        },
        {
          question: "What are the different types of home loan?",
          answer: `There are various types of home loans depending upon your specific requirement. Some of the key ones are as follows:
            <br/><strong>Land purchase loans</strong>: These loans are granted to individuals for the purchase of land on which they intend to build a house.
            <br/><strong>Home purchase loans</strong>: These are the most common type of home loans that is granted to individuals and they are granted for the purchase of an apartment.
            <br/><strong>Home construction loan</strong>: This type of loan is granted to individuals for the construction of a house on a plot of land that is already owned by the applicant.
            <br/><strong>Home Expansion/Extension Loan</strong>: This loan is specifically granted to individuals who want to expand their current home to include a new construction such as an additional floor, room, bathroom, etc.
            <br/><strong>Home Improvement Loan</strong>: Existing home owners who lack sufficient funds to renovate their existing home can apply for this loan to upgrade their home with a new paint job, electrical wiring, water proofing, etc.
            <br/><strong>Home conversion loans</strong>: Using this type of home loan, an existing home owner can add to their existing loan so that they can purchase a new house. This type of loan is only applicable to existing home owners.
            <br/><strong>NRI Home Loans</strong>: These home loans are specifically designed to provide non-resident Indians with financing so that they can purchase a home in India.`
        },
        {
          question: "What is the margin on a home loan?",
          answer:
            "The margin on a home loan refers to the percentage of the cost of the home that is not covered by the lender providing you with the home loan. On an average, lenders implement a 20% margin on home loans i.e. the home loan amount sanctioned to you will be 80% of the actual cost of the property. The remaining 20% of the home loan cost will have to be borne by you. Though the 20% margin is the industry average, lenders may increase or decrease their home loan margins on a case by case basis."
        }
      ]
    },
    loanAgainstProperty: {
      section: "loan-against-property",
      questions: [
        {
            question: "What is Loan against property?",
            answer:
              "Loan against property is nothing but a loan which you avail by keeping your commercial/residential property as a collateral. Another name for Loan against property is a secured loan. The security in this kind of loan is the property owned by the person applying for the loan"
          },
  
        {
          question: "What can Loan against Property be used for?",
          answer:
            "Any loan against a residential or commercial property can be used for both personal and business purposes. In fact, you can use it for anything other than speculative or non-prohibitive activities."
        },
        {
          question:
            "How does the lender decide on the amount I can get as Loan against property?",
          answer:
            "The bank analyzes at your repayment capacity. For calculating the loan amount, your income, age, qualifications, number of dependants, spouse’s income, assets, liabilities, stability and continuity of occupation and savings history are taken into consideration. However the eligibility of loan does not, generally, exceed 60 percent of the market value of the property(varies case to case)."
        },
        {
          question:
            "Can there be a co-applicant for Loan against property? If yes, who can be co-applicant?",
          answer:
            "You can include your spouse or a company as a co-applicant and that results in a higher amount being lent. However, if the property is co-owned, all co-owners mandatorily need to be co-applicants."
        },
        {
          question: "What are the processing fees for Loan Against Property?",
          answer:
            "Processing fee for loan against any property varies from bank to bank and is generally around 1% - 2%."
        },
        {
            question: "What is the Rate Of Interest(ROI) for Loan against property?",
            answer:
              "The ROI for Loan against property ranges between 10 to 14% depending on the lender and varies case to case"
          },
        {
          question:
            "How is the rate of interest on Loan against property calculated?",
          answer:
            "Interest is calculated on daily reducing balance. Your monthly out-go (equated monthly installment – EMI) is much lower as compared to the interest on annual reducing balance."
        },
        
        {
          question: "What is the tenure of the Loan against property?",
          answer:
            "Loans against property has a minimum tenure of 7 years and  maximum tenure of 15 years, subject to the condition it does not exceed your retirement age. This condition however can be flexible in certain cases"
        },
        {
            question: "What documents are required for Loan against property?",
            answer:
            `<strong>For Salaried:</strong>
            <ul>
            <li>2 photographs</li>
            <li>Copy of PAN Card</li>
            <li>Copy of Aadhar Card</li>
            <li>Address Proof (if its your own house require any 2 of mentioned documents  Eletricity bill, Ration card, Voter ID, Passport, Driving Licence) (if on rent require Rent agreement or Mobile Bill or Owners electricity bill)</li>
            <li>Latest 6 months salary slip</li> 
            <li>Latest 6 months updated bank statement with salary credit</li>
            <li>Appointment letter of current company (if unemployeed for more 3 months than relieving letter along with appointment letter of past company</li>
            <li>Form 16(last 2 years)</li></ul>
            <strong>For Self-Employed:</strong> 
            <ul><li>2 photographs</li>
            <li>Copy of PAN Card</li> 
            <li>Copy of Aadhar Card</li>
            <li>Address Proof</li>
            <li>Business Proof (Gumastha, GST certificate)</li>
            <li>Latest 3 years ITR - full set(Company and 51% Holding Director)</li>
            <li>Latest 1 year update bank statement(Company and 51% Holding Director)</li>
            <li>Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>List of Director and shareholding partners as on date (Pvt. Ltd. Co.)</li>
            <li>Business address proof (any utility bill)</li>
            <li>Any loans sanction letter or closure letter (latest 1 year)</li></ul><br/>
            <strong>Property documents:</strong> 
            <ul><li>Chain of agreement</li> 
            <li>Occupancy Certificate (OC)</li>
            <li>Completion Certificate (CC)</li> 
            <li>Blueprint - approved copy</li>
            <li>Latest maintainance bill</li>
            <li>Share certificate</li>
            <li>NOC from society/Builder</li></ul>
            <strong>Note:</strong> Additional documents may be required depending on bank to bank.
            <strong>Initial Cheque for legal valuation of property amounting between Rs 5000 to Rs 10000 or above</strong>`
          },
          {
            question: "What is average disbursal time for Loan against property?",
            answer:
              "The entire loan disbursal process takes around 10-25 days."
          },
          {
            question:
              "If a property is jointly owned by an individual and his/her relatives, can he/she take a loan against this property?",
            answer:
              "All the co-owners of the considered property need to come in as co-applicants to the loan."
          },
          {
            question: "What are the Foreclosure charges for Loan against property?",
            answer:
            "Foreclosure charges may vary lender to lender mostly between 1% - 4% and 0% if paid using own funds and primary applicant is an individual."
        }, 
          {
            question: "What are tax benefits for Loan against property?",
            answer:
              "Normally, a loan against property is, however, eligible for an exemption u/s 80C & 24(b) respectively. However, if the borrower has mortgaged his house in order to avail loan against property, there is no exemption. The advantage of a LAP in this instance is that the LAP interest rates are thus usually lower than that of other loans." 
  
          },  
          {
            question: "What if I default on my schedule Loan against property EMI?",
            answer:
              "In case you miss your scheduled loan EMIs and are unable to make future payments on your loan, the lender initially make attempts to recover the due amount through settlements and recovery agents. If such attempts fail and your loan account is marked as a default and property will be sealed by bank and sold to recover their amount."
          },
        {
          question: "How to repay my loan?",
          answer:
            "You repay the loan in Equated Monthly Installments (EMIs) comprising principal and interest. Repayment by way of EMI commences from the month following the month in which you take full disbursement."
        },
        {
          question: "What security will I have to provide?",
          answer: `As the name implies you need to mortgage your property for availing this loan. This mortgage is Equitable mortgage by Memorandum of Entry by way of deposit of title deeds and/or such other collateral security, as may be necessary. Collateral security for by way of assignment of insurance policy or any such other assignable financial instruments are also required, as security to loan if deem necessary by the Bank.
    Please do ensure that the title to the property is clear, marketable and free from encumbrance. To elaborate, there should not be any existing mortgage, loan or litigation which is likely to affect the title to the property adversely.`
        },
        {
          question: "Can I repay the loan ahead of schedule?",
          answer:
            "Yes. Prepayment is possible and there is no prepayment fee if you repay the loan after six months or 1 year (Lock in period varies lender to lender) of availing the loan if you pay from your own source of funds without transferring the loan."
        },
        {
          question:
            "How is my loan reassessed if there is a change in status from Non-Resident Indian to Resident Indian?",
          answer:
            "The repayment capacity of the applicant(s) based on Resident status is reassessed and a revised repayment schedule worked out. The new rate of interest will be as per the currently applicable rate of Resident Indian loans (for that specific loan product). This revised rate of interest would be applicable on the outstanding balance being converted. A letter is given to the customer confirming the change of status."
        }
      ]
    },
    workingCapital: {
      section: "working-capital",
      questions: [
        {
          question: "What is working capital?",
          answer:
            "Working capital loans is applicable for Small, Medium enterprises to augment their working capital requirement as well as meet their daily operational expenditure."
        },
        {
          question:
            "How is working capital finance typically structured at Angel Capital?",
          answer: 
          `At Angel Capital, working capital loans are tailored to suit the precise requirements of the client, in any of the various instruments available or structured as a combination of cash credit, demand loan, bill financing and non-funded facilities.
         The banks accomplished credit crew can gauge the credit needs of each client and frame the exact solutions.`
        },
        {
          question: "How does Angel Capital approve working capital loans?",
          answer: `Angel Capital's dedicated credit team has a deep understanding of the intricacies of various industries and is richly experienced in reckoning the business potential of companies.
    These informed professionals can assess your specific credit requirements and tailor customized financial solutions to suit your risk profile and the working capital cycle of your company.`
        },
        {
            question: "What documents are required for Working Capital loan?",
            answer:
              `
                <ul><li>2 photographs</li> 
                <li>Copy of PAN Card</li> 
                <li>Copy of Aadhar Card</li> 
                <li>Address Proof</li>
                <li>Business Proof (Gumastha, GST certificate) Latest 3 years ITR (Company and 51% Holding Director) - full set</li>
                <li>Latest 1 year update bank statement(Company and 51% Holding Director)</li>
                <li>Memorandum of Association (MOA) and Articles of Association (AOA)</li>
                <li>List of Director and shareholding partners as on date (Pvt. Ltd. Co.)</li> 
                <li>Business address proof (any utility bill)</li>
                <li>Bank Statements of company and 51% Holding Director</li>
                <li>Any loans sanction letter or closure letter (latest 1 year)</li></ul>
            <strong>Additional documents</strong>  <br/>
            <ul><li>Debtors List more than and Less then 6 Months</li> 
            <li>Creditors List more than and Less then 6 Months</li> 
            <li>Stock List more than and Less then 6 Months</li> 
            <li>Bad Debt List more than and Less then 6 Months</li> 
            </ul>
            <strong>Property documents:</strong> 
            <ul><li>Chain of agreement</li> 
            <li>Occupancy Certificate (OC)</li>
            <li>Completion Certificate (CC)</li> 
            <li>Blueprint - approved copy</li>
            <li>Latest maintainance bill</li>
            <li>Share certificate</li>
            <li>NOC from society/Builder</li></ul>
            <li>Initial Cheque varying from Rs10,000/- to 0.50% of loan amount proposed</li>
            <strong>Note:</strong> Additional documents may be required depending on bank to bank.`
          },
        {
          question: "What is the repayment schedule like?",
          answer:
            "Working capital finance limits are normally valid for one year and repayable on demand. Specific, self-liquidating loans are linked to the natural tenor of the transaction (bill finance, export credit etc.)."
        },
        {
          question: "What is an overdraft facility?",
          answer:
            "An overdraft limit is usually given against stock and book debts. It is given as a limit in your cash credit account and can be drawn and repaid depending on your cash flow needs."
        },
        {
          question: "Can the loan be used for any other purposes?",
          answer:
            "The overdraft facility can be and should be used only for working capital requirements like payment to debtors or towards inventory or expense. It should not be used to buy Capital assets and make long-term investment in assets, for which a term loan may be used."
        },
        {
          question: "Why should I consider working capital loan?",
          answer:
            "Although your business may be running smoothly, there will be lean periods when it won't have the cash to cover immediate or short-term costs. This could be because of delayed payments or unplanned expenditure. In these times, a working capital loan will help you meet daily expenses."
        },
        {
          question: "What is the average disbursal time for  working capital?",
          answer:
            "Depending on your loan provider, you can get your working capital loan in as short a timespan of approximately 1 month after you submit the required documents."
        },
        {
            question:
              "What if I default on my scheduled Working capital EMI?",
            answer:
              "In case you miss your scheduled loan EMIs and are unable to make future payments on your loan, the lender initially make attempts to recover the due amount through settlements and recovery agents. If such attempts fail and your loan account is marked as a default and will have additional defaulter charges."
          },
          {
            question:
              "Are there any tax benefits associated with Working capital Loan?",
            answer:
              "No tax benefit are available for working capital loan"
          },
          {
            question: "What are the Foreclosure charges for Loan against property?",
            answer:
            "Foreclosure charges may vary lender to lender mostly 1% and above, 0% if paid using own funds and primary applicant is an individual."
        }, 
        {
            question: "What is the Rate Of Interest(ROI) for Working capital loan?",
            answer:
              "The ROI for Working capital loan ranges between 9 to 14% depending on the lender and varies case to case"
          },
  
        {
          question:
            "Do I need to nominate a guarantor or attach security to quality for this loan?",
          answer:
            "Usually, working capital loans are unsecured loans. So, you can avail working capital without pledging any collateral. Property Hypothecation with major lenders is required. Further, no guarantor is required."
        },
        {
          question:
            "What is the processing fees associated with a working capital loan?",
          answer:
            "Processing fees for working capital loan is usually around 1% - 3%."
        }
      ]
    },
    leaseRentalDiscounting: {
      section: "lease-rental-discounting",
      questions: [
        {
            question:
              "What is Lease Rental Discounting?",
            answer:
              "Lease Rental Discounting (LRD) is a term loan offered against rental receipts derived from lease contracts with corporate tenants. The loan is provided to the lesser based on the discounted value of the rentals and the underlying property value. It is another method to obtain finance from bank or other financial institute or lending institutes. Lease Rent Discount (LRD) consideration is between the borrower who owns the premises, the tenant who has rented the said premises or taken on lease and the bank or financial institute or Corporate. The rent is considered as fixed income over a stipulated time i.e. Lease or rent period or tenure. The agreement is between the borrower and lender and the major term of repayment is the rent which is directly deposited with the lender and not with the borrower. The Borrower is sanctioned a loan based upon the rent to be collected over the period of lease."
          },
  
        {
          question: "How do you determine the LRD loan eligibility?",
          answer: `We consider the following factors to determine eligibility:
          <br/>
    Valuation of property (Fair market value) – up to 55% of the value of the commercial property<br/>
    Existing rental from the property being mortgaged – up to 90% of the net rental receipts.`
        },
        {
          question:
            "What will be my approved tenure for LRD Loan?",
          answer:
            "The loan can be availed for a tenure between 7 - 15 years "
        },
        {
            question:
              "What is the average disbursal time for LRD Loan?",
            answer:
              "The average disbursal time for LRD Loan is typically 10 - 25 working days."
          },
          {
            question: "What is the Rate of Interest(ROI) charged on LRD Loans?",
            answer:
              "The ROI ranges between 10% to 14% and varies across different Lenders"
          },
          {
            question: "What documents are required for Lease Rental Discounting?",
            answer:
            `<strong>For Salaried:</strong>
            <ul>
            <li>2 photographs</li>
            <li>Copy of PAN Card</li>
            <li>Copy of Aadhar Card</li>
            <li>Address Proof (if its your own house require any 2 of mentioned documents  Eletricity bill, Ration card, Voter ID, Passport, Driving Licence) (if on rent require Rent agreement or Mobile Bill or Owners electricity bill)</li>
            <li>Latest 6 months salary slip</li> 
            <li>Latest 6 months updated bank statement</li>
            <li>Appointment letter of current company (if unemployeed for more 3 months than relieving letter along with appointment letter of past company</li>
            <li>Form 16(last 2 years)</li></ul>
            <strong>For Self-Employed:</strong> 
            <ul><li>2 photographs</li>
            <li>Copy of PAN Card</li> 
            <li>Copy of Aadhar Card</li>
            <li>Address Proof</li>
            <li>Business Proof (Gumastha, GST certificate)</li>
            <li>Latest 3 years ITR - full set (Company and 51% Holding Director)</li>
            <li>Latest 1 year update bank statement(Company and 51% Holding Director)</li>
            <li>Memorandum of Association (MOA) and Articles of Association (AOA)</li>
            <li>List of Director and shareholding partners as on date (Pvt. Ltd. Co.)</li>
            <li>Business address proof (any utility bill)</li>
            <li>Any loans sanction letter or closure letter (latest 1 year)</li></ul><br/>
            <strong>Property documents:</strong> 
            <ul><li>Chain of agreement</li> 
            <li>Occupancy Certificate (OC)</li>
            <li>Completion Certificate (CC)</li> 
            <li>Blueprint - approved copy</li>
            <li>Latest maintainance bill</li>
            <li>Share certificate</li>
            <li>NOC from society/Builder</li></ul>
            <strong>Note:</strong> Additional documents may be required depending on bank to bank.
            <strong>Initial Cheque for legal valuation of property amounting between Rs 5000 to Rs 10000 or above</strong>
            `
          },
        {
          question: "How can I repay my LRD Loan?",
          answer:
            "Your EMI will be debited from the ESCROW account where the rental amount will be deposited by your lessee."
        },
        {
            question: "What are the processing fees for LRD Loan?",
            answer:
              "Processing fees for LRD Loan varies from bank to bank and is generally around 1% and above."
          },
        {
          question: "What is Floating Rate LRD Loan?",
          answer:
        `Adjustable Rate LRD Loans or floating rate LRD  loans are the ones in which the interest rates change throughout the duration of your home loan. As interest rates in the economy move up and down, it gets reflected on the rate of interest you will be charged on your LRD  loan`
        },
        {
          question: "On what basis does the internal Floating Reference Rate(FRR) change?",
          answer:
            `Internal Floating Reference Rate(FRR) is the benchmark reference rate. This is determined based on the market conditions and the cost of funds for the company. This changes, depending on various external factors and economic conditions.
            The changes are done by the lenders in response to the policies regulated by the RBI`
        },
        {
          question: "Who does the Floating Reference Rate(FRR) change?",
          answer:
            `
              The EMI of a floating rate loan changes with changes in market interest rates. If market rates increase, your repayment increases. When rates fall, your dues also fall. <br/>The floating interest rate is made up of two parts:
              <br/>Index and Spread<br/>
              The index is measure of the interest rates generally(based on government securities prices) and the spread is an extra amount that the banker adds to cover credit risk, profit markup etc.
              The amount of spread may differ from one lender to another, but it is usually constant over the lifetime over the loan. If the index rate moves up, so does your interest rate in most circumstances and you will have to pay higher EMI.
              Conversely if interest rate moves down, your EMI amount should also be lower.
              Also sometimes banks make some adjustments so that your EMI remains constant in such cases, when a lender increases the floating interest rate the tenure of the loan is increased(EMI kept constant)
            `
        },
        {
          question: "How often do Interest rates change?",
          answer:
            "As per general re-pricing policy, interest rates are reviewed every two months and a decision is taken whether to change the interest rates or keep it unchanged with respect to the policies of the RBI."
        },
        {
          question:
            "I have taken a loan just one month back. How does change in interest rates work for me?",
          answer:
            "As per general internal pricing policy re-pricing policy, interest rates will be changed only for those cases which are at least 3 months old. In your case, change in FRR will not have an immediate impact on interest rate of your loan. However once your loan is 3 months old, any changes in FRR in last 3 months will change your effective rate basis the margin on your loan."
        },
        {
          question:
            "What is the TAT (Turn Around Time) for Foreclosure Statement?",
          answer:
            "The TAT for issuance foreclosure statement is typically 15 working days."
        }
      ]
    },
    builderAndProjectFunding: {
      section: "builder-and-project-funding",
      questions: [
        {
            question: "What is Builder and Project Funding Loan?",
            answer:
              "Banking and non-banking lenders are offering loans for New constructions as real Estate is India's rapidly developing business segment and multiple financial institutions. Loan is given to construct or develop a new real estate project including both residential as well as commercial is known as Construction Loan under Project finance department. This construction finance can be used by an individual or a firm or company engaged in the business of real estate development or construction (Builder) under Project finance / loan department. Project finance / Construction Finance is the long term financing of infrastructure based upon the projected cash flows of the project rather than the balance sheets of the project sponsors."
          },
  
        {
          question: "Who can apply for builder and project funding?",
          answer:
            "Builders or developers with minimum 3 projects/1lac sqft experience can avail this facility"
        },
        {
          question:
            "Who are eligible for Project Finance/Construction finance?",
          answer: `The general factors taken into account while determining the eligibility of project finance are listed below:<br/>
    <ul><li> The promoters should have proven track record and have successfully completed minimum 3 projects</li>
    <li>The developer or the builder should have the land ownership in their name or firms name.</li></ul>
    `
        },
        {
          question: "Which projects can be funded?",
          answer: `Vacant Land : Land ownership in firms or promoters name.<br/>
    : Development agreement with the land owners<br/>
    SRA Project : Salable portions of the construction cost Can be financed.(COMMENCENT CERTIFICATE OF THE SALEABLE PORTION MUST)<br/>
    Redevelopment project : In redevelopment project only saleable portion of the construction cost can be financed if commencement certificate of the saleable area is available<br/>
    `
        },
        {
          question: "What are the process for availing Project Finance?",
          answer: `Construction Finance Project Report <br/>
    <ul>
    <li>Project Evaluation</li>
    <li>Profile of the builder/developer</li>
    <li>Documentation</li>
    <li>Property Valuation</li>
    <li>Personal discussion</li>
    <li>Sanctioning of the Loan</li>
    <li>Disbursement (slab wise)</li></ul>
    The Process may vary from bank to bank in which they will do your verification of documents, residence, office, and a cibil check
    `
        },
        {
          question: "What are the loan tenure options for Project finance?",
          answer:
            "The loan tenure ranges between 1 - 5 years"
        },
        {
          question:
            "What are the documents required for applying for project finance?",
          answer: `Financial Documents<br/>
                <ul><li>2 photographs</li> 
                <li>Copy of PAN Card</li> 
                <li>Copy of Aadhar Card</li> 
                <li>Address Proof</li>
                <li>Business Proof (Gumastha, GST certificate) Latest 3 years ITR - full set(Company and 51% Holding director)</li>
                <li>Latest 1 year update bank statement(Company and 51% Holding director)</li>
                <li>Memorandum of Association (MOA) and Articles of Association (AOA)</li>
                <li>List of Director and shareholding partners as on date (Pvt. Ltd. Co.)</li> 
                <li>Business address proof (any utility bill)</li>
                <li>Any loans sanction letter or closure letter (latest 1 year)</li></ul>
              <ul>
              <li>Last 3 project completion report</li>
              <li>Lastest ongoing and upcoming project report</li>
              <li>All Nocs from Government Authorities</li>
              <li>SRA, POA agreement for SRA Project</li>

              <li>Development Project report, POA - Redevelopment Project</li>
              <li>NOC from builder and MHADA / Relevant Authority</li>
              <li>Development Project report, POA - Redevelopment Project</li>
             
              <li>Initial Cheque for Technical and Legal valuation of property amounting to Rs 25000 and above</li></ul>
              Property Documents
              <ul><li>Title Clearance Report</li>
                <li>Chain of Agreement (if any) </li>
                <li>Development agreement </li>
                <li>Plan (approved) Copy</li>
                <li> Receipts of all fees and charges paid to the government</li>
              <li>Additional Documents may be required on demand depending on the lender</li>
              </ul>`
        },
        {
          question: "How much loan can I get for project funding?",
          answer: "It depends upon the project and varies from lender to lender"
        },
        {
            question: "What is the Rate of Interest(ROI) charged on LRD Loans?",
            answer:
              "The ROI ranges between 14% to 20% and varies across different Lenders"
          },
        {
          question:
            "How much time does it take for the disbursement of the loan?",
          answer:
            "It takes more around 1 month or more from the day of completion of documents and it may vary from lender to lender"
        },
        {
          question: "How can i repay my loan?",
          answer:
            "The loan will be repaid through the capitalization/prepayment stipulated. It also be repaid through the principal component of EMI whenever EMI starts (after the moratorium period)"
        },
        {
          question: "Can i prepay my loan ?",
          answer:
            "The loan can be prepaid although there are prepayment penalties stipulated. It varies from lender to lender."
        }
      ]
    }
  }
};

export default content;
