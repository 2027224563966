import React, { Component, Fragment } from "react";
import WOW from "wowjs";
import "../assets/styles/App.scss";
import Header from "../components/header/Header";
import Footer from '../components/footer/Footer';
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";

import PersonalLoan from './services/serviceDetails/PersonalLoan';
import BusinessLoan from './services/serviceDetails/BusinessLoan';
import HomeLoan from './services/serviceDetails/HomeLoan';
import WorkingCapital from './services/serviceDetails/WorkingCapital';
import LoanAgainstProperty from './services/serviceDetails/LoanAgainstProperty';
import LeaseRentalDiscounting from "./services/serviceDetails/LeaseRentalDiscounting";
import BuilderAndProjectFunding from "./services/serviceDetails/BuilderAndProjectFunding";

import ContactUs from './ContactUs';
import AboutUs from "./AboutUs";
import Home from './Home';
import ServicesList from './services/ServicesList';
import FaqList from './Faqs';
import ApplicationForm from './ApplicationForm';
import EmiCalculator from './EmiCalculator';
import PrivacyPolicy from './PrivacyPolicy';
import NotFound from './NotFound';
import { Helmet } from 'react-helmet';
import  logo from '../assets/images/angel-capital-logo.png';





class App extends Component {

  componentDidMount() {
    const wow = new WOW.WOW();
    wow.init();
  }
  render() {
    return (
      <div className="App" >
      <Helmet>
      <title>
      Loans Aggregators and Financers | Angel Capital
  </title>
  <meta
    name="description"
    content="India's Leading financial experts in Loans and financing space. We provide customized loan services to all our customers"
  />
  <link rel="canonical" href="https://www.angelcapital.in/" />
  <meta property="og:locale" content="en_US" />
  <meta property="og:type" content="website" />
  <meta
    property="og:title"
    content="Loans Aggregators and Financers | Angel Capital"
  />
  <meta
    property="og:description"
    content="Start with Peer to Peer (P2P) Lending in India with the most trusted P2P platform. A borrower can get personal loans &amp; investors can earn higher returns by lending money online through P2P Lending with LenDenClub. For investors, lower default, higher RoI &amp; collection support is our expertise in Peer to Peer lending in India."
  />
  <meta property="og:url" content="https://www.angelcapital.in/" />
  <meta property="og:site_name" content="LenDenClub" />
  <meta
    property="og:image"
    content={`https://www.angelcapital.in/${logo}`}
  />
  <meta
    property="og:image:secure_url"
    content={`https://www.angelcapital.in/${logo}`}
  />
  <meta property="og:image:width" content="1024" />
  <meta property="og:image:height" content="538" />
      </Helmet>
        <Router>
          <Route>
            <Fragment>
            <Header />
            <Switch>
              <Route path='/' exact component={Home}/>
              <Route path='/personal-loan' component={PersonalLoan}/>
              <Route path='/business-loan' component={BusinessLoan}/>
              <Route path='/home-loan' component={HomeLoan}/>
              <Route path='/loan-against-property' component={LoanAgainstProperty}/>
              <Route path='/working-capital' component={WorkingCapital}/>
              <Route path='/lease-rental-discounting' component={LeaseRentalDiscounting}/>
              <Route path='/builder-and-project-funding' component={BuilderAndProjectFunding}/>
              <Route path='/contact-us' component={ContactUs}/>
              <Route path='/services' component={ServicesList}/>
              <Route path='/about-us' component={AboutUs}/>
              <Route path='/faqs' component={FaqList}/>
              <Route path='/apply-now' component={ApplicationForm} />
              <Route path='/emi-calculator' component={EmiCalculator} />
              <Route path='/privacy-policy' component={PrivacyPolicy} />
              <Route component={NotFound} />

            </Switch>
            <Footer/>
            </Fragment>
          </Route>
        </Router>
      </div>
    );
  }
}

export default App;
