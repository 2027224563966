import React from "react";
import { Container, Row, Col } from "reactstrap";

export default props => {
  let benefits = null;
  if (props.details.benefits) {
    benefits = (
      <Col xs={12} md={6} className='benefits-of-service wow slideInRight'>
          
      <h3> Benefits</h3>

        <ul>
         {
           props.details.benefits.map(item => <li key={item}>{item}</li>) 
         }

        </ul>
      </Col>
    )
  }
  return (
    <div className="service-info">
      <Container>
        <Row>
          <Col xs={12}>
            <h2 className="wow slideInLeft">{props.serviceTitle}</h2>
            <br />
            <p className="service-detail-para wow slideInRight">{props.serviceDetail}</p>
          <p className="service-detail-para wow slideInRight">{props.details.paragraphRight}</p>

          </Col>
        </Row>
        <Row flex="true">
          <Col xs={12} md={6} >
          <img src={require(`../../assets/images/${props.details.image}`)} alt='personal loan.jpg' className='img-fluid wow slideInLeft'/>
          </Col>
         
         {benefits}
         <Col xs={12} md={12} className="mt-5">
          {/* <h3>{props.serviceTitle}</h3> */}


          </Col>
        </Row>
      </Container>
    </div>
  );
};
