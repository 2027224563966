import React, { Fragment, Component } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemTitle,
  AccordionItemBody,
} from "react-accessible-accordion";
import { Container, Row, Col, FormGroup, Input, InputGroup } from "reactstrap";
import "react-accessible-accordion/dist/fancy-example.css";

export default class Faqs extends Component {
  state = {
    itemList: this.props.items,
  }

  handleSearch = (e) => {
    const searchValue = e.target.value;
    let itemList = this.state.itemList;
    itemList = this.props.items.filter(item=> item.question.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1  || item.answer.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1 );
    this.setState({itemList: itemList})
  }
  render() {
  let faqs = (
    <Fragment>
      <AccordionItem>
        <AccordionItemTitle>
          <h4>Simple title</h4>
        </AccordionItemTitle>
        <AccordionItemBody>
          <p>Body content</p>
        </AccordionItemBody>
      </AccordionItem>
      <AccordionItem>
        <AccordionItemTitle>
          <h4>Simple title</h4>
        </AccordionItemTitle>
        <AccordionItemBody>
          <p>Body content</p>
        </AccordionItemBody>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemTitle>
          <h4>Simple title</h4>
        </AccordionItemTitle>
        <AccordionItemBody>
          <p>Body content</p>
        </AccordionItemBody>
      </AccordionItem>

      <AccordionItem>
        <AccordionItemTitle>
          <h4>Simple title</h4>
        </AccordionItemTitle>
        <AccordionItemBody>
          <p>Body content</p>
        </AccordionItemBody>
      </AccordionItem>
      </Fragment>
  );
  if (this.state.itemList)
    faqs = this.state.itemList.map((item, index) => (
      <AccordionItem key={index}>
        <AccordionItemTitle >
          <h4>{item.question}</h4>
        </AccordionItemTitle>
        <AccordionItemBody>
          <p dangerouslySetInnerHTML={{__html: item.answer}}></p>
        </AccordionItemBody>
      </AccordionItem>
    ));
  return (
    <Container>
      <Row>
        <Col>
          <h3>FAQs</h3>
          <h5>Have any questions or doubts?</h5>
          <br />
          {this.props.search && (
            <FormGroup>
            <InputGroup>
            <Input
            placeholder="&#128269; Search for your questions"
            onChange={this.handleSearch}
            className="faq-search mb-5"
            />
            </InputGroup>
          </FormGroup>
          )}   
          <Accordion >
          {faqs}
    </Accordion>
    {!this.props.search && (
            <FormGroup className="text-center">
              <a href="/faqs" className="btn btn-primary mt-5"> Read More</a>
          </FormGroup>
          )}
        </Col>
      </Row>
    </Container>
  );
}
};